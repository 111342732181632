export type BadRequestValidationError = {
  children?: BadRequestValidationError[];
  constraints?: Record<string, string>;
  property: string;
  target: any;
  value: any;
};

export default class BadRequestError extends Error {
  public errors: BadRequestValidationError[] = [];

  constructor(message?: string, errors?: BadRequestValidationError[]) {
    super(message);
    if (errors) {
      this.errors = errors;
    }
  }
}
