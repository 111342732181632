import type { ExportResult } from '@opentelemetry/core';
import { ConsoleLogRecordExporter } from '@opentelemetry/sdk-logs';
import type { ReadableLogRecord } from '@opentelemetry/sdk-logs/build/src/export/ReadableLogRecord';
import type { Severity } from '../types';

export class ConsoleLogExporter extends ConsoleLogRecordExporter {
  public export(
    logs: ReadableLogRecord[],
    resultCallback: (result: ExportResult) => void,
  ): void {
    logs.forEach((l) => {
      // eslint-disable-next-line no-console
      console[l.severityText as Severity](
        `${l.body}: ${JSON.stringify(
          Object.keys(l.attributes)
            .filter((k) => k.startsWith('data'))
            .reduce(
              (o, k) => ({ ...o, [k.substring(5)]: l.attributes[k] }),
              {},
            ),
          null,
          2,
        )}`,
      );
    });
    resultCallback({ code: 0 });
  }
}
