import { FunctionComponent, ReactElement } from 'react';

const WalletAddressIcon: FunctionComponent = (): ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M15.8333 16.6667H4.16667C3.24619 16.6667 2.5 15.9205 2.5 15V7.50001C2.5 6.57954 3.24619 5.83334 4.16667 5.83334H15.8333C16.7538 5.83334 17.5 6.57954 17.5 7.50001V15C17.5 15.9205 16.7538 16.6667 15.8333 16.6667Z"
      stroke="#898395"
      strokeWidth="1.5"
    />
    <path
      d="M14.1667 13.3333C13.2463 13.3333 12.5 12.587 12.5 11.6667C12.5 10.7463 13.2463 10 14.1667 10C15.087 10 15.8333 10.7463 15.8333 11.6667C15.8333 12.587 15.087 13.3333 14.1667 13.3333Z"
      fill="#898395"
    />
    <path
      d="M15 5.83333V4.66935C15 3.5743 13.962 2.7768 12.9039 3.05895L3.73723 5.5034C3.00764 5.69796 2.5 6.35871 2.5 7.1138V7.5"
      stroke="#898395"
      strokeWidth="1.5"
    />
  </svg>
);

export default WalletAddressIcon;
