import { Logger } from './Logger';
import { ConfigArg, getConfig } from './config';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type DecoratedFunction = (...args: any[]) => any;

type InSpanReturnValue = (
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  target: any,
  propertyKey: string,
  descriptor: TypedPropertyDescriptor<DecoratedFunction>,
) => void;

export function createInSpanDecorator(logger: Logger) {
  return (config?: ConfigArg): InSpanReturnValue => {
    return (_target, propertyKey, descriptor): void => {
      const originalFunc = descriptor.value as DecoratedFunction;

      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      descriptor.value = function decoratedFunc(...args: any[]): any {
        return logger.withSpan(config ? getConfig(config) : propertyKey, () =>
          originalFunc.apply(this, args),
        );
      };
    };
  };
}
