import { FunctionComponent } from 'react';

type IconProps = {
  disabled?: boolean;
};

const CheckboxIcon: FunctionComponent<IconProps> = ({ disabled = false }) => {
  return (
    <svg
      className={disabled ? 'text-fill-secondary' : 'text-label-primary'}
      viewBox="0 0 24 24"
      style={{
        fill: 'none',
        stroke: 'currentColor',
        strokeWidth: 2,
      }}
    >
      <polyline points="20 6 9 17 4 12" />
    </svg>
  );
};

export default CheckboxIcon;
