import { useEffect, useState } from 'react';
import {
  storageGetItem,
  storageRemoveItem,
  storageSetItem,
} from 'src/utils/browserStorage';

const isMoonPayCom =
  window.self !== window.top &&
  window.location.ancestorOrigins?.contains('https://www.moonpay.com');

export enum ColorSchemeVariant {
  Light = 'light',
  Dark = 'dark',
}

const getColorSchemeFromQuery = (): ColorSchemeVariant => {
  const urlParams = new URLSearchParams(window.location.search);

  const themeFromQuery: ColorSchemeVariant | null = urlParams.get(
    'theme',
  ) as ColorSchemeVariant;

  return themeFromQuery;
};

const getSystemPreferredScheme = (): ColorSchemeVariant => {
  const systemPrefersDark = window?.matchMedia(
    '(prefers-color-scheme: dark)',
  ).matches;

  const systemColorScheme = systemPrefersDark
    ? ColorSchemeVariant.Dark
    : ColorSchemeVariant.Light;

  return systemColorScheme;
};

const getDefaultColorScheme = (): ColorSchemeVariant => {
  const themeFromQuery: ColorSchemeVariant = getColorSchemeFromQuery();

  try {
    if (window.localStorage) {
      const localStorageColorScheme: ColorSchemeVariant | null = storageGetItem(
        'color-scheme',
        localStorage,
      ) as ColorSchemeVariant;

      const systemColorScheme = getSystemPreferredScheme();

      let mappedThemeFromQuery: ColorSchemeVariant | null = null;

      if (themeFromQuery === ColorSchemeVariant.Light) {
        mappedThemeFromQuery = ColorSchemeVariant.Light;
      }

      if (themeFromQuery === ColorSchemeVariant.Dark) {
        mappedThemeFromQuery = ColorSchemeVariant.Dark;
      }

      return (
        mappedThemeFromQuery || localStorageColorScheme || systemColorScheme
      );
    }
  } catch (e) {
    if (themeFromQuery === ColorSchemeVariant.Dark) {
      return ColorSchemeVariant.Dark;
    }

    return ColorSchemeVariant.Light;
  }

  return ColorSchemeVariant.Light;
};

const useColorSchemeSetup = (): {
  value: ColorSchemeVariant;
  setColorScheme: (value: ColorSchemeVariant) => void;
  setFollowSystemPreference: () => void;
} => {
  const [colorScheme, setColorScheme] = useState<ColorSchemeVariant>(
    getDefaultColorScheme(),
  );

  const handleColorSchemeUpdate = (value: ColorSchemeVariant) => {
    setColorScheme(value);

    storageSetItem('color-scheme', value, localStorage);
  };

  const handleFollowSystemColorScheme = () => {
    storageRemoveItem('color-scheme');

    const systemColorScheme = getSystemPreferredScheme();

    setColorScheme(systemColorScheme);
  };

  useEffect(() => {
    // When preferred system color scheme changes
    // We want to call getDefaultColorScheme to check if there is already a value in local storage
    // and if there is use that value, otherwise use the system value.
    // Local storage value will take precedence over system value
    // Reason for that is the customer manually updated color scheme in the widget
    // and we want to respect that value unless explicitly checked to follow system

    const colorSchemeQuery = window?.matchMedia('(prefers-color-scheme: dark)');

    // Check if browser supports addEventListener on matchMedia
    colorSchemeQuery?.addEventListener?.('change', () => {
      const newColorScheme = getDefaultColorScheme();
      setColorScheme(newColorScheme);
    });

    return () => {
      colorSchemeQuery?.removeEventListener?.('change', getDefaultColorScheme);
    };
  }, []);

  useEffect(() => {
    // Updating apple mobile status bar meta tag
    // To make sure status bar theme is updated
    // based on color scheme

    const appleStatusBarMetaTag = document.querySelector(
      'meta[name="apple-mobile-web-app-status-bar-style"]',
    );

    const themeColorMetaTag = document.querySelector(
      'meta[name="theme-color"]',
    );

    if (colorScheme === ColorSchemeVariant.Dark) {
      document.body.classList.add('dark');

      // Settings status bar color and surrounding
      appleStatusBarMetaTag?.setAttribute('content', 'black-translucent');
      themeColorMetaTag?.setAttribute('content', '#1C1C1E');
    } else {
      document.body.classList.remove('dark');

      // Settings status bar color and surrounding

      appleStatusBarMetaTag?.setAttribute('content', 'white-translucent');
      themeColorMetaTag?.setAttribute('content', '#FFFFFF');
    }
  }, [colorScheme]);

  return {
    // if parent is not moonpay.com we will use the color scheme value
    // otherwise we will use the default light scheme.
    value: !isMoonPayCom ? colorScheme : ColorSchemeVariant.Light,
    setColorScheme: handleColorSchemeUpdate,
    setFollowSystemPreference: handleFollowSystemColorScheme,
  };
};

export default useColorSchemeSetup;
