import { FunctionComponent, ReactElement } from 'react';

const ChevronDownIcon: FunctionComponent = (): ReactElement => (
  <svg
    className="text-label-tertiary"
    fill="none"
    height="8"
    stroke="currentColor"
    strokeLinecap="round"
    strokeLinejoin="round"
    strokeWidth="2"
    viewBox="0 0 14 8"
    width="14"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M6,9l6,6,6-6" transform="translate(-5 -8)" />
  </svg>
);

export default ChevronDownIcon;
