import { FunctionComponent } from 'react';

const ShoppingBagIcon: FunctionComponent = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="72"
        height="72"
        rx="22.5"
        fill="#7D00FF"
        fillOpacity="0.08"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M27 24V25.5H23.0257C21.1066 25.5 19.4972 26.9489 19.2963 28.8574L16.77 52.8574C16.537 55.0716 18.273 57 20.4994 57H51.5009C53.7273 57 55.4633 55.0716 55.2303 52.8574L52.704 28.8574C52.5031 26.9489 50.8937 25.5 48.9746 25.5H45V24C45 19.0294 40.9706 15 36 15C31.0294 15 27 19.0294 27 24ZM36 18C32.6863 18 30 20.6863 30 24V25.5H42V24C42 20.6863 39.3137 18 36 18ZM30 34.5C30 37.8137 32.6863 40.5 36 40.5C39.3137 40.5 42 37.8137 42 34.5V33C42 32.1716 42.6716 31.5 43.5 31.5C44.3284 31.5 45 32.1716 45 33V34.5C45 39.4706 40.9706 43.5 36 43.5C31.0294 43.5 27 39.4706 27 34.5V33C27 32.1716 27.6716 31.5 28.5 31.5C29.3284 31.5 30 32.1716 30 33V34.5Z"
        fill="#7D00FF"
      />
    </svg>
  );
};

export default ShoppingBagIcon;
