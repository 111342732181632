import { ClipboardEvent } from 'react';

const ForbiddenKeyCodes: Record<string, string> = {
  ArrowLeft: 'ArrowLeft',
  ArrowUp: 'ArrowUp',
  ArrowRight: 'ArrowRight',
  ArrowDown: 'ArrowDown',
  E: 'E',
  e: 'e',
  Minus: '-',
  Plus: '+',
  Comma: ',',
  Dot: '.',
  // Getting this key on smartphone when pressing "-" on numeric keyboard
  Unidentified: 'Unidentified',
};

export const FORBIDDEN_KEY_CODES = Object.values(ForbiddenKeyCodes);

export const VERIFICATION_CODE_LENGTH = 6;

export const isDigitOrEmptyString = (value: string, length: number): boolean =>
  new RegExp(`^[0-9]{1,${length}}$`).test(value) || !value;

export const sanitizeValue = (value: string): string =>
  value?.replace(/[^\d]+/g, '') ?? '';

export const getVerificationCodeFromClipboard = (
  event: ClipboardEvent<HTMLInputElement>,
  length?: number,
): string => {
  const value = event?.clipboardData?.getData('text') ?? '';
  const sanitizedValue = sanitizeValue(value) ?? '';
  return sanitizedValue.substring(0, length ?? sanitizedValue.length);
};
