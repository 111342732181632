var _a, _b, _c;
export var WalletMessageType;
(function (WalletMessageType) {
    WalletMessageType["READY"] = "wallet-ready";
    WalletMessageType["MESSAGE_PROXY"] = "wallet-message-proxy";
    WalletMessageType["MESSAGE_PROXY_RESPONSE"] = "wallet-message-proxy-response";
    WalletMessageType["PROMPT_CHANGE"] = "wallet-prompt-change";
    WalletMessageType["EXIT"] = "wallet-exit";
})(WalletMessageType || (WalletMessageType = {}));
// TODO: deprecate in either dhw or here
export var SecureWalletUxMode;
(function (SecureWalletUxMode) {
    SecureWalletUxMode["NoUx"] = "no-ux";
    SecureWalletUxMode["Prompt"] = "prompt";
})(SecureWalletUxMode || (SecureWalletUxMode = {}));
export var WalletNetwork;
(function (WalletNetwork) {
    WalletNetwork["Ethereum"] = "ethereum";
    WalletNetwork["Bitcoin"] = "bitcoin";
    WalletNetwork["Solana"] = "solana";
})(WalletNetwork || (WalletNetwork = {}));
export var WalletChainsSupported = (_a = {},
    _a[WalletNetwork.Ethereum] = [1, 137, 80001, 31337, 11155111],
    _a[WalletNetwork.Bitcoin] = [0, 1],
    _a[WalletNetwork.Solana] = [900, 901],
    _a);
export var SECURE_WALLET_SUPPORTED_NETWORKS = [
    WalletNetwork.Bitcoin,
    WalletNetwork.Ethereum,
    WalletNetwork.Solana,
];
export var WalletNetworkSymbol;
(function (WalletNetworkSymbol) {
    WalletNetworkSymbol["ETH"] = "eth";
    WalletNetworkSymbol["BTC"] = "btc";
    WalletNetworkSymbol["SOL"] = "sol";
})(WalletNetworkSymbol || (WalletNetworkSymbol = {}));
export var WalletNetworkToSymbolMap = (_b = {},
    _b[WalletNetwork.Ethereum] = WalletNetworkSymbol.ETH,
    _b[WalletNetwork.Bitcoin] = WalletNetworkSymbol.BTC,
    _b[WalletNetwork.Solana] = WalletNetworkSymbol.SOL,
    _b);
export function walletChainIdToChain(network, chainId) {
    if (network === WalletNetwork.Bitcoin) {
        switch (chainId) {
            case 0:
                return {
                    chainId: 0,
                    chainName: 'Bitcoin Mainnet',
                    nativeCurrency: { name: 'Bitcoin', symbol: 'BTC' },
                };
            case 1:
                return {
                    chainId: 1,
                    chainName: 'Bitcoin Testnet',
                    nativeCurrency: { name: 'Bitcoin', symbol: 'BTC' },
                };
            default:
                return {
                    chainId: chainId,
                    chainName: 'Bitcoin Unknown',
                    nativeCurrency: { name: 'Unknown', symbol: 'UNK' },
                };
        }
    }
    switch (chainId) {
        case 1:
            return {
                chainId: 1,
                chainName: 'Ethereum Mainnet',
                nativeCurrency: { name: 'Ether', symbol: 'ETH' },
            };
        case 11155111:
            return {
                chainId: 11155111,
                chainName: 'Sepolia',
                nativeCurrency: { name: 'Sepolia Ether', symbol: 'ETH' },
            };
        case 137:
            return {
                chainId: 137,
                chainName: 'Polygon Mainnet',
                nativeCurrency: { name: 'MATIC', symbol: 'MATIC' },
            };
        case 80001:
            return {
                chainId: 80001,
                chainName: 'Mumbai',
                nativeCurrency: { name: 'MATIC', symbol: 'MATIC' },
            };
        case 31337:
            return {
                chainId: 31337,
                chainName: 'EVM Emulator',
                nativeCurrency: { name: 'Local Ether', symbol: 'LETH' },
            };
        default:
            return {
                chainId: chainId,
                chainName: 'EVM Unknown',
                nativeCurrency: { name: 'Unknown', symbol: 'UNK' },
            };
    }
}
export var WalletSymbolToNetworkMap = (_c = {},
    _c[WalletNetworkSymbol.ETH] = WalletNetwork.Ethereum,
    _c[WalletNetworkSymbol.BTC] = WalletNetwork.Bitcoin,
    _c);
