import { FunctionComponent } from 'react';

const ChainLinksIcon: FunctionComponent = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="72"
        height="72"
        rx="22.5"
        fill="#7D00FF"
        fillOpacity="0.08"
      />
      <path
        d="M51.8033 20.1967C48.8744 17.2678 44.1256 17.2678 41.1967 20.1967L32.1967 29.1967C29.2678 32.1256 29.2678 36.8744 32.1967 39.8033C32.8184 40.425 33.5195 40.9131 34.2661 41.2697C35.0136 41.6268 35.3301 42.5222 34.973 43.2697C34.6159 44.0173 33.7204 44.3337 32.9729 43.9767C31.9234 43.4753 30.9413 42.7905 30.0754 41.9246C25.9749 37.8241 25.9749 31.1759 30.0754 27.0754L39.0754 18.0754C43.1759 13.9749 49.8241 13.9749 53.9246 18.0754C58.0251 22.1759 58.0251 28.8241 53.9246 32.9246L50.4107 36.4386C49.8249 37.0244 48.8751 37.0244 48.2894 36.4386C47.7036 35.8528 47.7036 34.903 48.2894 34.3172L51.8033 30.8033C54.7322 27.8744 54.7322 23.1256 51.8033 20.1967ZM37.027 28.7303C37.3841 27.9828 38.2796 27.6663 39.0271 28.0233C40.0766 28.5247 41.0587 29.2095 41.9246 30.0754C46.0251 34.1759 46.0251 40.8241 41.9246 44.9246L32.9246 53.9246C28.8241 58.0251 22.1759 58.0251 18.0754 53.9246C13.9749 49.8241 13.9749 43.1759 18.0754 39.0754L21.5893 35.5614C22.1751 34.9757 23.1249 34.9757 23.7106 35.5614C24.2964 36.1472 24.2964 37.097 23.7106 37.6828L20.1967 41.1967C17.2678 44.1256 17.2678 48.8744 20.1967 51.8033C23.1256 54.7322 27.8744 54.7322 30.8033 51.8033L39.8033 42.8033C42.7322 39.8744 42.7322 35.1256 39.8033 32.1967C39.1816 31.575 38.4805 31.0869 37.7339 30.7303C36.9864 30.3732 36.6699 29.4778 37.027 28.7303Z"
        fill="#7D00FF"
      />
    </svg>
  );
};

export default ChainLinksIcon;
