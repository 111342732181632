import { FunctionComponent } from 'react';

const UnknownIcon: FunctionComponent = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 232 232"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="116" cy="116" r="116" fill="#505050" />
      <path
        d="M102.216 153.091V152.239C102.311 143.195 103.258 135.998 105.057 130.648C106.856 125.297 109.413 120.965 112.727 117.651C116.042 114.336 120.019 111.282 124.659 108.489C127.453 106.784 129.962 104.772 132.188 102.452C134.413 100.084 136.165 97.3617 137.443 94.2841C138.769 91.2064 139.432 87.7973 139.432 84.0568C139.432 79.4167 138.343 75.392 136.165 71.9829C133.987 68.5739 131.075 65.946 127.429 64.0994C123.783 62.2528 119.735 61.3295 115.284 61.3295C111.402 61.3295 107.661 62.1345 104.062 63.7443C100.464 65.3542 97.4574 67.8873 95.0426 71.3437C92.6278 74.8002 91.2311 79.322 90.8523 84.9091H72.9545C73.3333 76.8598 75.4167 69.9706 79.2045 64.2415C83.0398 58.5123 88.0824 54.1326 94.3324 51.1023C100.63 48.072 107.614 46.5568 115.284 46.5568C123.617 46.5568 130.862 48.214 137.017 51.5284C143.22 54.8428 148.002 59.3883 151.364 65.1648C154.773 70.9413 156.477 77.5227 156.477 84.9091C156.477 90.1174 155.672 94.8286 154.062 99.0426C152.5 103.257 150.227 107.021 147.244 110.335C144.309 113.65 140.758 116.585 136.591 119.142C132.424 121.746 129.086 124.492 126.577 127.381C124.067 130.222 122.244 133.607 121.108 137.537C119.972 141.467 119.356 146.367 119.261 152.239V153.091H102.216ZM111.307 195.136C107.803 195.136 104.796 193.882 102.287 191.372C99.7775 188.863 98.5227 185.856 98.5227 182.352C98.5227 178.848 99.7775 175.842 102.287 173.332C104.796 170.823 107.803 169.568 111.307 169.568C114.811 169.568 117.817 170.823 120.327 173.332C122.836 175.842 124.091 178.848 124.091 182.352C124.091 184.672 123.499 186.803 122.315 188.744C121.179 190.686 119.64 192.248 117.699 193.432C115.805 194.568 113.674 195.136 111.307 195.136Z"
        fill="#DCDCDC"
      />
    </svg>
  );
};

export default UnknownIcon;
