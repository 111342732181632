import { FunctionComponent } from 'react';

const BitcoinIcon: FunctionComponent = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="12" cy="12" r="12" fill="#F7931A" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M17.724 10.432C17.9751 8.75084 16.6951 7.84711 14.9449 7.24422L15.5126 4.96691L14.126 4.62144L13.5733 6.83874C13.2092 6.74793 12.8349 6.66226 12.463 6.57737L13.0197 4.34547L11.6343 4L11.0662 6.27652C10.7645 6.20782 10.4684 6.13991 10.181 6.06845L10.1826 6.06134L8.27085 5.58401L7.90209 7.06458C7.90209 7.06458 8.93059 7.30028 8.90888 7.31489C9.47031 7.45505 9.57217 7.82658 9.5548 8.12111L8.90809 10.7155C8.94678 10.7253 8.99692 10.7395 9.0522 10.7617L8.90612 10.7253L7.99922 14.3596C7.93052 14.5302 7.75641 14.786 7.36396 14.6889C7.37778 14.709 6.35638 14.4374 6.35638 14.4374L5.66821 16.0246L7.47253 16.4743C7.67625 16.5253 7.87749 16.5778 8.07652 16.6298L8.07669 16.6298C8.20548 16.6634 8.33335 16.6968 8.46037 16.7293L7.8867 19.0331L9.27132 19.3786L9.83986 17.0997C10.2177 17.2023 10.5849 17.2971 10.9442 17.3863L10.378 19.6545L11.7642 20L12.3379 17.701C14.7016 18.1483 16.4795 17.9679 17.2269 15.8303C17.8298 14.1089 17.1973 13.116 15.9536 12.4681C16.8593 12.2584 17.5416 11.6626 17.7236 10.432H17.724ZM14.5562 14.8734C14.1627 16.4533 11.6892 15.7996 10.5584 15.5007L10.5584 15.5007C10.4572 15.474 10.3667 15.4501 10.2894 15.4308L11.0506 12.3793C11.1451 12.4029 11.2604 12.4288 11.3911 12.4581L11.3912 12.4581C12.561 12.7206 14.9583 13.2585 14.5566 14.8734H14.5562ZM11.6263 11.042C12.5698 11.2937 14.6264 11.8424 14.9846 10.4071C15.3509 8.93884 13.3511 8.49629 12.3744 8.28016C12.2646 8.25586 12.1677 8.23442 12.0886 8.21471L11.3985 10.9824C11.4638 10.9987 11.5404 11.0191 11.6263 11.042Z"
        fill="white"
      />
    </svg>
  );
};

export default BitcoinIcon;
