import InformationCircleIcon from '@heroicons/react/outline/InformationCircleIcon';
import { uuidV4 } from '@moonpay-widget/common';
import Tippy from '@tippyjs/react';
import clsx from 'clsx';
import { PropsWithChildren, useMemo } from 'react';
import { TooltipProps } from './types';

const Tooltip = ({
  className,
  iconClassName,
  children,
  onShown,
  tabIndex,
  customInfoIcon,
}: PropsWithChildren<TooltipProps>) => {
  const id = useMemo(() => `tooltip-${uuidV4()}`, []);

  return (
    <Tippy
      arrow
      className={className}
      // onShown={onShown} does not work and crashes if you decide not to pass onShown prop
      // The reason is due to Tippy having some logic which does something like
      // if('onShown' in tippyProps) and this evaluates to true and they try to call onShown which is undefined
      {...(onShown ? { onShown } : {})}
      content={
        <span role="tooltip" id={id}>
          {children}
        </span>
      }
    >
      <span
        className={clsx(
          iconClassName,
          'focus:outline-none focus:shadow-outline',
        )}
        data-testid="tooltip-icon"
        aria-describedby={id}
        tabIndex={tabIndex || 0}
        // Assigning a role rather than using a button element to avoid occasions where this might be nested in an interactive element already
        role="button"
      >
        {customInfoIcon?.component || (
          <InformationCircleIcon className="w-4 h-4" />
        )}
      </span>
    </Tippy>
  );
};

export default Tooltip;
