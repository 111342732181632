import type { SpanOptions } from '@opentelemetry/api/build/src/trace/SpanOptions';
import type { Context } from '@opentelemetry/api';

export interface Config {
  spanName: string;
  options?: SpanOptions;
  context?: Context;
}

export type ConfigArg = Config | string;

export function getConfig(config: ConfigArg): Config {
  return typeof config === 'string'
    ? {
        spanName: config,
        options: undefined,
        context: undefined,
      }
    : config;
}
