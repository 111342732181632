import { FunctionComponent, ReactElement } from 'react';

const ClipboardListIcon: FunctionComponent = (): ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M7.5 4.16667H5.83333C4.91286 4.16667 4.16667 4.91286 4.16667 5.83333V15.8333C4.16667 16.7538 4.91286 17.5 5.83333 17.5H14.1667C15.0871 17.5 15.8333 16.7538 15.8333 15.8333V5.83333C15.8333 4.91286 15.0871 4.16667 14.1667 4.16667H12.5M7.5 4.16667C7.5 5.08714 8.24619 5.83333 9.16667 5.83333H10.8333C11.7538 5.83333 12.5 5.08714 12.5 4.16667M7.5 4.16667C7.5 3.24619 8.24619 2.5 9.16667 2.5H10.8333C11.7538 2.5 12.5 3.24619 12.5 4.16667M10 10H12.5M10 13.3333H12.5M7.5 10H7.50833M7.5 13.3333H7.50833"
      stroke="#898395"
      strokeWidth="1.5"
      strokeLinecap="round"
    />
  </svg>
);

export default ClipboardListIcon;
