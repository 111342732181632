import { FunctionComponent, ReactElement } from 'react';

const TransactionApprovalIcon: FunctionComponent = (): ReactElement => (
  <svg
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M10.8334 2.50072C10.7538 2.5 10.6644 2.5 10.5623 2.5H6.83352C5.9001 2.5 5.43304 2.5 5.07652 2.68166C4.76291 2.84144 4.50813 3.09623 4.34835 3.40983C4.16669 3.76635 4.16669 4.23341 4.16669 5.16683V14.8335C4.16669 15.7669 4.16669 16.2334 4.34835 16.5899C4.50813 16.9035 4.76291 17.1587 5.07652 17.3185C5.4327 17.5 5.89919 17.5 6.8308 17.5H13.1693C14.1009 17.5 14.5667 17.5 14.9229 17.3185C15.2364 17.1587 15.4921 16.9035 15.6519 16.5899C15.8334 16.2337 15.8334 15.7679 15.8334 14.8363V7.7714C15.8334 7.66913 15.8334 7.57967 15.8326 7.5M10.8334 2.50072C11.0714 2.50289 11.2219 2.51154 11.3657 2.54605C11.5358 2.58687 11.6983 2.65438 11.8474 2.74577C12.0155 2.84881 12.1599 2.99318 12.4479 3.28125L15.0525 5.88582C15.3408 6.17408 15.4841 6.3178 15.5872 6.48599C15.6785 6.63512 15.7461 6.79772 15.7869 6.96777C15.8214 7.11147 15.8303 7.26209 15.8326 7.5M10.8334 2.50072V4.83333C10.8334 5.76675 10.8334 6.23314 11.015 6.58966C11.1748 6.90327 11.4296 7.15873 11.7432 7.31853C12.0994 7.5 12.5659 7.5 13.4974 7.5H15.8326"
      stroke="#898395"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
    <path
      d="M9.99999 9.6C9.17157 9.6 8.5 10.1373 8.5 10.8C8.5 11.4627 9.17157 12 9.99999 12C10.8284 12 11.5 12.5373 11.5 13.2C11.5 13.8627 10.8284 14.4 10 14.4M9.99999 9.6C10.5552 9.6 11.04 9.84132 11.2993 10.2M9.99999 9.6V9M9.99999 9.6L10 14.4M10 14.4L9.99999 15M10 14.4C9.4448 14.4 8.96004 14.1587 8.70068 13.8"
      stroke="#898395"
      strokeLinecap="round"
    />
  </svg>
);

export default TransactionApprovalIcon;
