import type { AttributeValue } from '@opentelemetry/api';

export class LogPayloadParser {
  // Using set as the lookup time is much faster ( ~O(1) ) compared to list
  private readonly RESTRICTED_KEYS = new Set([
    'password',
    'token',
    'secret',
    'csrfToken',
    'customerToken',
    'encryptedWallet',
    'cipherText',
    'iv',
    'privateKey',
    'walletToken',
    'mnemonic',
  ]);

  public addRestrictedKeys(keys: string[]): void {
    keys?.forEach((k) => this.RESTRICTED_KEYS.add(k));
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  public sanitisePayload(data: any): any {
    if (typeof data === 'object' && data !== null) {
      if (Array.isArray(data)) {
        return data.map((item) => this.sanitisePayload(item));
      }
      Object.keys(data).forEach((key) => {
        if (!this.RESTRICTED_KEYS.has(key)) {
          data[key] = this.sanitisePayload(data[key]);
        } else {
          delete data[key];
        }
      });
      return data;
    }

    return data;
  }

  /**
   * Converts nested objects into dot based objects expected by otel
   * ie
   * {a: {foo: 1} } -> {"a.foo": 1}
   */
  public objectToOtelNotation(
    payload: Record<string, unknown>,
    currentPath = '',
  ): Record<string, AttributeValue> {
    return Object.entries(payload).reduce(
      (acc: Record<string, AttributeValue>, [key, value]) => {
        const newKey = currentPath ? `${currentPath}.${key}` : key;
        if (value && typeof value === 'object' && !Array.isArray(value)) {
          Object.assign(
            acc,
            this.objectToOtelNotation(value as Record<string, unknown>, newKey),
          );
        } else {
          acc[newKey] = value as AttributeValue;
        }
        return acc;
      },
      {},
    );
  }
}
