import { FunctionComponent, ReactElement } from 'react';

const SignatureIcon: FunctionComponent = (): ReactElement => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" rx="22.5" fill="#7D00FF" fillOpacity="0.08" />
    <path
      d="M43.1716 23.1716C44.7337 21.6095 47.2663 21.6095 48.8284 23.1716C50.3905 24.7337 50.3905 27.2663 48.8284 28.8284L47.2426 30.4142L41.5858 24.7574L43.1716 23.1716Z"
      fill="#7D00FF"
    />
    <path
      d="M38.7574 27.5858L22 44.3431V50H27.6568L44.4142 33.2426L38.7574 27.5858Z"
      fill="#7D00FF"
    />
  </svg>
);

export default SignatureIcon;
