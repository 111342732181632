import { FunctionComponent, ReactElement } from 'react';

interface DuplicateProps {
  size?: number;
  className?: string;
}

const Duplicate: FunctionComponent<DuplicateProps> = ({
  size = 24,
  className,
}): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 20 20"
    fill="none"
    className={className}
  >
    <path
      d="M6.66673 13.3335H5.00006C4.07959 13.3335 3.3334 12.5873 3.3334 11.6668V5.00016C3.3334 4.07969 4.07959 3.3335 5.00006 3.3335H11.6667C12.5872 3.3335 13.3334 4.07969 13.3334 5.00016V6.66683M8.3334 16.6668H15.0001C15.9205 16.6668 16.6667 15.9206 16.6667 15.0002V8.3335C16.6667 7.41302 15.9205 6.66683 15.0001 6.66683H8.3334C7.41292 6.66683 6.66673 7.41302 6.66673 8.3335V15.0002C6.66673 15.9206 7.41292 16.6668 8.3334 16.6668Z"
      stroke="#221A39"
      strokeOpacity="0.6"
      strokeWidth="2"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default Duplicate;
