import { FunctionComponent } from 'react';

const SecretIcon: FunctionComponent = () => {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
    >
      <rect
        width="72"
        height="72"
        rx="22.5"
        fill="#7D00FF"
        fillOpacity="0.08"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M23.4142 20.5858C22.6332 19.8047 21.3668 19.8047 20.5858 20.5858C19.8047 21.3668 19.8047 22.6332 20.5858 23.4142L48.5858 51.4142C49.3668 52.1953 50.6332 52.1953 51.4142 51.4142C52.1953 50.6332 52.1953 49.3668 51.4142 48.5858L48.4679 45.6394C51.5429 43.1848 53.878 39.8422 55.0848 35.9999C52.5363 27.8858 44.9557 22 36.0004 22C32.7533 22 29.6869 22.7738 26.9756 24.1472L23.4142 20.5858ZM31.9363 29.1078L34.964 32.1356C35.2946 32.0472 35.642 32 36.0005 32C38.2096 32 40.0005 33.7909 40.0005 36C40.0005 36.3585 39.9534 36.7059 39.8649 37.0365L42.8927 40.0643C43.5966 38.8732 44.0005 37.4837 44.0005 36C44.0005 31.5817 40.4188 28 36.0005 28C34.5168 28 33.1273 28.4039 31.9363 29.1078Z"
        fill="#7715F5"
      />
      <path
        d="M40.9082 49.3934L35.4993 43.9846C31.4803 43.7361 28.2644 40.5202 28.016 36.5012L20.6698 29.1551C19.0013 31.1445 17.7127 33.4634 16.916 36.0001C19.4646 44.1143 27.0451 50 36.0004 50C37.6937 50 39.3379 49.7896 40.9082 49.3934Z"
        fill="#7715F5"
      />
    </svg>
  );
};

export default SecretIcon;
