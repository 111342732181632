import { FunctionComponent, ReactElement } from 'react';

const AccessCurrencyRequestIcon: FunctionComponent = (): ReactElement => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" rx="22.5" fill="#7D00FF" fillOpacity="0.08" />
    <path
      d="M51 26.625C51 31.1123 44.2843 34.75 36 34.75C27.7157 34.75 21 31.1123 21 26.625C21 22.1377 27.7157 18.5 36 18.5C44.2843 18.5 51 22.1377 51 26.625Z"
      fill="#7D00FF"
    />
    <path
      d="M36 37.25C40.4753 37.25 44.6509 36.2728 47.7973 34.5685C48.9473 33.9456 50.0413 33.1739 50.9578 32.2612C50.9858 32.4638 51 32.6685 51 32.875C51 37.3623 44.2843 41 36 41C27.7157 41 21 37.3623 21 32.875C21 32.6685 21.0142 32.4638 21.0422 32.2612C21.9587 33.1739 23.0527 33.9456 24.2027 34.5685C27.3491 36.2728 31.5247 37.25 36 37.25Z"
      fill="#7D00FF"
    />
    <path
      d="M36 43.5C40.4753 43.5 44.6509 42.5228 47.7973 40.8185C48.9473 40.1956 50.0413 39.4239 50.9578 38.5112C50.9858 38.7138 51 38.9185 51 39.125C51 43.6123 44.2843 47.25 36 47.25C27.7157 47.25 21 43.6123 21 39.125C21 38.9185 21.0142 38.7138 21.0422 38.5112C21.9587 39.4239 23.0527 40.1956 24.2027 40.8185C27.3491 42.5228 31.5247 43.5 36 43.5Z"
      fill="#7D00FF"
    />
    <path
      d="M36 49.75C40.4753 49.75 44.6509 48.7728 47.7973 47.0685C48.9473 46.4456 50.0413 45.6739 50.9578 44.7612C50.9858 44.9638 51 45.1685 51 45.375C51 49.8623 44.2843 53.5 36 53.5C27.7157 53.5 21 49.8623 21 45.375C21 45.1685 21.0142 44.9638 21.0422 44.7612C21.9587 45.6739 23.0527 46.4456 24.2027 47.0685C27.3491 48.7728 31.5247 49.75 36 49.75Z"
      fill="#7D00FF"
    />
  </svg>
);

export default AccessCurrencyRequestIcon;
