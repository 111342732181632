import React, { useEffect, useState } from 'react';
import CopyToClipboard from 'react-copy-to-clipboard';
import { Text } from '@moonpay-widget/ui-kit';
import { formatWalletAddress } from '../../utils/formatters';

interface CopyCryptoAddressFieldProps {
  text: string;
  onClick?: () => void;
  float?: string;
}

const CopyCryptoAddressField: React.FunctionComponent<
  CopyCryptoAddressFieldProps
> = ({ text, onClick, float = 'start' }) => {
  const [copied, setCopied] = useState(false);

  useEffect(() => {
    if (!copied) {
      return;
    }
    const timeout = setTimeout(() => {
      setCopied(false);
    }, 1000);
    // eslint-disable-next-line consistent-return
    return () => clearTimeout(timeout);
  }, [setCopied, copied]);

  return (
    <CopyToClipboard text={text}>
      <button
        type="button"
        className={`flex w-full justify-${float} leading-tight rounded-lg cursor-pointer`}
        onClick={() => {
          setCopied(true);
          if (onClick) {
            onClick();
          }
        }}
      >
        <Text variant="callout" className="font-light">
          {formatWalletAddress({ address: text })}
        </Text>
      </button>
    </CopyToClipboard>
  );
};

export default CopyCryptoAddressField;
