import { FunctionComponent } from 'react';

const ShareIcon: FunctionComponent = () => {
  return (
    <svg
      width="72"
      height="72"
      viewBox="0 0 72 72"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <rect
        width="72"
        height="72"
        rx="22.5"
        fill="#7D00FF"
        fillOpacity="0.08"
      />
      <path
        d="M34.9393 15.4393C35.5251 14.8536 36.4749 14.8536 37.0607 15.4393L43.0607 21.4393C43.6464 22.0251 43.6464 22.9749 43.0607 23.5607C42.4749 24.1464 41.5251 24.1464 40.9393 23.5607L37.5 20.1213L37.5 27H34.5V20.1213L31.0607 23.5607C30.4749 24.1464 29.5251 24.1464 28.9393 23.5607C28.3536 22.9749 28.3536 22.0251 28.9393 21.4393L34.9393 15.4393Z"
        fill="#7D00FF"
      />
      <path
        d="M34.5 27L34.5 42C34.5 42.8284 35.1716 43.5 36 43.5C36.8284 43.5 37.5 42.8284 37.5 42V27H45C48.3137 27 51 29.6863 51 33V51C51 54.3137 48.3137 57 45 57H27C23.6863 57 21 54.3137 21 51V33C21 29.6863 23.6863 27 27 27H34.5Z"
        fill="#7D00FF"
      />
    </svg>
  );
};

export default ShareIcon;
