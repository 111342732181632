import ChevronRight from '@heroicons/react/solid/ChevronRightIcon';
import { FunctionComponent, ReactElement } from 'react';
import { SOLID_ICON_HEIGHT, SOLID_ICON_WIDTH } from './constants';

interface ChevronRightIconProps {
  width?: string | number;
  height?: string | number;
  color?: string;
  className?: string;
}
const ChevronRightIcon: FunctionComponent<ChevronRightIconProps> = ({
  width = SOLID_ICON_WIDTH,
  height = SOLID_ICON_HEIGHT,
  color = 'var(--label-tertiary)',
  className,
}): ReactElement => (
  <ChevronRight
    data-testid="chevron-right-icon"
    width={width}
    height={height}
    color={color}
    className={className}
  />
);

export default ChevronRightIcon;
