import { Button, Container, Icon, Text } from '@moonpay-widget/ui-kit';
import clsx from 'clsx';
import { ChangeEvent, FunctionComponent, ReactNode, useState } from 'react';
import TermsOfUse from 'src/components/AccountConsent/TermsOfUse';
import {
  MoonpayStaticLogoWithoutText,
  MoonpayStaticLogoWithoutTextDark,
} from 'src/components/MoonPayStaticLogoWithoutText';
import useColorScheme from 'src/contexts/ColorScheme/useColorScheme';
import { ColorSchemeVariant } from 'src/hooks/useColorSchemeSetup';
import {
  AccountTermsPrivacyDetails,
  CustomerAccountConnection,
} from 'src/wallet/walletProvider/kms/kmsApi';

type MidSectionProps = {
  accountName: string;
  appName?: string;
  accountLogo?: string | FunctionComponent<Record<string, unknown>>;
  title: string;
  message?: string;
  children: ReactNode;
};

const MidSection: FunctionComponent<MidSectionProps> = ({
  accountLogo,
  accountName,
  title,
  appName,
  message,
  children,
}) => {
  const { value: colorScheme } = useColorScheme();
  let logo = accountLogo;
  if (!accountLogo) {
    logo =
      colorScheme === ColorSchemeVariant.Dark
        ? MoonpayStaticLogoWithoutTextDark
        : MoonpayStaticLogoWithoutText;
  }

  return (
    <div className="px-4 mt-4 mb-2 justify-top">
      {logo && (
        <div className="flex flex-row items-center justify-center m-4 text-center">
          <Icon
            icon={logo}
            iconAlt={accountName}
            className="w-16 h-16 rounded-xl"
          />
        </div>
      )}
      <div>
        <Text className="mt-4 mb-6 text-center" variant="title1" bold>
          {`${title} ${appName || ''}`}
        </Text>
        <Text
          variant="footnote"
          className="mt-2 text-center text-label-secondary"
        >
          {message}
        </Text>
      </div>

      <div className="min-h-64 justify-top">{children}</div>
    </div>
  );
};

type BottomSectionProps = {
  aboveButtonsText?: string;
  approveButtonText?: string;
  cancelButtonText?: string;
  cancelDisabled?: boolean;
  approvalDisabled?: boolean;
  approvalHidden?: boolean;
  onCancelClicked: () => void;
  onApproveClicked: () => void;
  noModal?: boolean;
  account?: AccountTermsPrivacyDetails;
  connection?: CustomerAccountConnection;
};

const BottomSection: FunctionComponent<BottomSectionProps> = ({
  onCancelClicked,
  onApproveClicked,
  approveButtonText = 'Approve',
  cancelButtonText = 'Cancel',
  cancelDisabled = false,
  aboveButtonsText,
  approvalDisabled = false,
  approvalHidden = false,
  noModal = false,
  account,
  connection,
}) => {
  const onApprove = (event: React.MouseEvent<HTMLButtonElement>) => {
    onApproveClicked();
    event.preventDefault();
  };
  const onCancel = (event: React.MouseEvent<HTMLButtonElement>) => {
    onCancelClicked();
    event.preventDefault();
  };

  const [currApprovalDisabled, setCurrApprovalDisabled] =
    useState<boolean>(approvalDisabled);

  const toggleApproval = (value: boolean) => {
    if (value) {
      setCurrApprovalDisabled(false);
    } else {
      setCurrApprovalDisabled(true);
    }
  };

  const renderTos =
    (account?.privacyLink && !connection?.privacyPolicyAccepted) ||
    (account?.termsLink && !connection?.tosAccepted);

  return (
    <div
      className={clsx(
        'flex dialog-actions flex-col items-center text-center shadow-lg',
        !noModal && 'mb-6',
      )}
    >
      {account && connection && (
        <div className="flex-row items-center justify-between pt-6 m-6 text-left dialog-actions">
          {renderTos && (
            <TermsOfUse
              className="mt-2"
              account={account}
              onChange={(event: ChangeEvent<HTMLInputElement>): void => {
                toggleApproval(event.target.checked);
              }}
            />
          )}
        </div>
      )}
      <div className="flex flex-row items-center justify-between px-2 pt-4 text-center dialog-actions">
        <Text variant="footnote" className="text-label-secondary">
          {aboveButtonsText}
        </Text>
      </div>
      <div className="flex flex-col-reverse items-center justify-between w-full px-4 pb-4 dialog-actions sm:flex-row">
        {!cancelDisabled && (
          <Button
            variant="custom"
            className="mx-2 border-none bg-fill-secondary text-label-tertiary"
            margin="small"
            onClick={onCancel}
          >
            <Text variant="headline" bold className="text-label-primary">
              {cancelButtonText}
            </Text>
          </Button>
        )}
        {!approvalHidden && (
          <Button
            variant="custom"
            className="mx-2 bg-btn-primary hover:bg-btn-hover disabled:bg-fill-secondary text-btn-label disabled:text-label-tertiary"
            margin="small"
            onClick={onApprove}
            disabled={currApprovalDisabled}
          >
            <Text variant="headline" bold>
              {approveButtonText}
            </Text>
          </Button>
        )}
      </div>
    </div>
  );
};

type SecureWalletContainerProps = MidSectionProps &
  BottomSectionProps & {
    accountName: string;
    fitToContent?: boolean;
    noModal?: boolean;
  };

const SecureWalletContainer: FunctionComponent<SecureWalletContainerProps> = (
  props,
) => {
  const { noModal, fitToContent, children } = props;
  return (
    <Container
      component="div"
      padding="0"
      showFooter={!noModal}
      fitFull={noModal}
      style={{
        marginTop: noModal ? '0vh' : '6vh',
        minHeight: '0px',
      }}
      className={clsx(
        '!rounded-[24px] text-label-primary bg-system-background-primary',
        noModal && 'h-full',
      )}
    >
      {fitToContent ? (
        <>
          {children}
          <BottomSection {...props} />
        </>
      ) : (
        <>
          <MidSection {...props} />
          <BottomSection {...props} />
        </>
      )}
    </Container>
  );
};

export default SecureWalletContainer;
