import { FunctionComponent, ReactElement } from 'react';

const SendAssetIcon: FunctionComponent = (): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
  >
    <rect width="72" height="72" rx="22.5" fill="#7D00FF" fillOpacity="0.08" />
    <g clipPath="url(#clip0_2796_11029)">
      <g clipPath="url(#clip1_2796_11029)">
        <path
          d="M37.7889 21.1056C37.4501 20.428 36.7576 20 36 20C35.2425 20 34.55 20.428 34.2112 21.1056L20.2112 49.1056C19.8565 49.815 19.9545 50.6669 20.461 51.2773C20.9675 51.8876 21.7868 52.1409 22.5495 51.923L32.5494 49.0659C33.408 48.8206 34 48.0358 34 47.1429V38C34 36.8954 34.8954 36 36 36C37.1046 36 38 36.8954 38 38V47.1429C38 48.0358 38.592 48.8206 39.4506 49.0659L49.4506 51.923C50.2132 52.1409 51.0325 51.8876 51.539 51.2773C52.0456 50.6669 52.1436 49.815 51.7889 49.1056L37.7889 21.1056Z"
          fill="#7D00FF"
        />
      </g>
    </g>
    <defs>
      <clipPath id="clip0_2796_11029">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(16 16)"
        />
      </clipPath>
      <clipPath id="clip1_2796_11029">
        <rect
          width="40"
          height="40"
          fill="white"
          transform="translate(16 16)"
        />
      </clipPath>
    </defs>
  </svg>
);

export default SendAssetIcon;
