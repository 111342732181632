/* eslint-disable no-bitwise */
// Copy from rootdir/src/utils/uuid.ts

// Based on the answers from: https://stackoverflow.com/q/105034
// Specifically: https://stackoverflow.com/a/2117523
export const uuidV4: () => string =
  typeof window.crypto !== 'undefined' &&
  typeof window.crypto.getRandomValues !== 'undefined'
    ? () => {
        // If we have a cryptographically secure PRNG, use that
        return (([1e7] as any) + -1e3 + -4e3 + -8e3 + -1e11).replace(
          /[018]/g,
          (c: number) =>
            (
              c ^
              ((crypto.getRandomValues(new Uint8Array(1))[0] as number) &
                (15 >> (c / 4)))
            ).toString(16),
        );
      }
    : () => {
        // Otherwise, just use Math.random
        return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, (c) => {
          const r = (Math.random() * 16) | 0;
          const v = c === 'x' ? r : (r & 0x3) | 0x8;
          return v.toString(16);
        });
      };

// Some API do not like the formal string representation of UUID (https://www.rfc-editor.org/rfc/rfc4122#page-4)
// replaceAll cannot be used as it is not supported by IE
export const uuidV4NoDashes: () => string = () => uuidV4().replace(/-/g, '');

export const isValidUUID = (uuid: string): boolean => {
  const uuidRegex = /^[0-9a-f]{8}-[0-9a-f]{4}-[1-5][0-9a-f]{3}-[89ab][0-9a-f]{3}-[0-9a-f]{12}$/i;
  return uuidRegex.test(uuid);
};
