export const isIframe = (): boolean => {
  try {
    return window.self !== window.top;
  } catch (e) {
    return true;
  }
};

export const isMoonpayIframe = (): boolean => {
  return (
    isIframe() &&
    (window.location.ancestorOrigins?.contains('https://www.moonpay.com') ||
      window.location.ancestorOrigins?.contains('http://localhost:3001') ||
      window.location.ancestorOrigins?.contains(
        'https://account.moonpay.com',
      ) ||
      window.location.ancestorOrigins?.contains('http://localhost:3002'))
  );
};

export const isCameraAllowed = (): boolean => {
  return (
    (document as any).featurePolicy &&
    (document as any).featurePolicy.allowsFeature('camera')
  );
};

export const isPaymentRequestAllowed = (): boolean => {
  return (
    (document as any).featurePolicy &&
    (document as any).featurePolicy.allowsFeature('payment')
  );
};
