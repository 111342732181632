const environment = {
  moonpayDhwClientId: process.env.REACT_APP_MOONPAY_DHW_CLIENT_ID,

  // RPC providers URLs
  ethereumMainnetRpcProviderUrl:
    process.env.REACT_APP_ETHEREUM_MAINNET_RPC_PROVIDER_URL ||
    'https://eth.llamarpc.com',
  ethereumSepoliaRpcProviderUrl:
    process.env.REACT_APP_ETHEREUM_TESTNET_RPC_PROVIDER_URL ||
    'https://eth-sepolia.g.alchemy.com/v2/ebOOzYrgZs2IxDXSsgjxYLh2zc8lyQbP',
  polygonMainnetRpcProviderUrl:
    process.env.REACT_APP_POLYGON_MAINNET_RPC_PROVIDER_URL ||
    'https://polygon-rpc.com',
  polygonTestnetRpcProviderUrl:
    process.env.REACT_APP_POLYGON_TESTNET_RPC_PROVIDER_URL ||
    'https://rpc-mumbai.maticvigil.com',
  // This was originally REACT_APP_MOONPAY_API_KEY
  moonpayApiKey: process.env.REACT_APP_API_KEY || 'pk_test_123',
  // This was originally REACT_APP_MOONPAY_API_URL
  moonpayApiUrl: process.env.REACT_APP_MOONPAY_API_REST_URL,
  userCustodialWalletAssetId:
    process.env.REACT_APP_USER_CUSTODIAL_WALLET_ASSET_ID,
  nodeEnv: process.env.NODE_ENV,
  secureWalletUrl:
    process.env.REACT_APP_SECURE_WALLET_DOMAIN || 'http://localhost:3005',
  isLiveMode: Boolean(process.env.REACT_APP_LIVE_MODE) || false,
  launchdarklyClientSideId:
    process.env.REACT_APP_LAUNCHDARKLY_CLIENT_SIDE_ID || '',
  moonpayWalletApiUrl:
    process.env.REACT_APP_MOONPAY_WALLET_API_URL || 'http://localhost:4010',
};

export default environment;
