import { TextElementType, TextVariant } from './types';

export const getElementTypeForVariant = (
  variant: TextVariant,
): TextElementType => {
  switch (variant) {
    case 'largeTitle':
      return 'h1';

    case 'title1':
      return 'h2';

    case 'title2':
      return 'h3';

    case 'title3':
      return 'h4';

    case 'headline':
      return 'h5';

    case 'subhead':
      return 'h6';

    case 'body':
    case 'callout':
    case 'footnote':
    case 'caption1':
    case 'caption2':
    default:
      return 'p';
  }
};
