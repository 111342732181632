import clsx from 'clsx';
import { FunctionComponent, ReactElement } from 'react';

interface CopyIconProps {
  size?: number;
  className?: string;
}

const CopyIcon: FunctionComponent<CopyIconProps> = ({
  size = 24,
  className,
}): ReactElement => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width={size}
    height={size}
    viewBox="0 0 24 24"
    fill="none"
    stroke="currentColor"
    strokeWidth="2"
    strokeLinecap="round"
    strokeLinejoin="round"
    className={clsx('feather feather-copy', className)}
  >
    <rect x="9" y="9" width="13" height="13" rx="2" ry="2" />
    <path d="M5 15H4a2 2 0 0 1-2-2V4a2 2 0 0 1 2-2h9a2 2 0 0 1 2 2v1" />
  </svg>
);

export default CopyIcon;
