import { darken, transparentize } from 'polished';
import { FC, ReactNode } from 'react';
import { MoonPayThemeBase } from 'theming/theme';
import { MoonPayTheme } from 'theming/types';

/**
 * See tailwind.config.js for more info about variables usage
 */
const createGlobalStyle = (props: { theme: MoonPayTheme }) => `
  :root {
    --trustpilot-color: #00b67a;
  }

  body {
    /* Callisto System - Colors */

    /* Light theme */
    color-scheme: light;

    --moonpay-brand: #7d00ff;
    --moonpay-body-background: #ffffff;

    /* Accents */
    --accent-primary:  ${props.theme.light.accentPrimary};
    --accent-secondary: ${props.theme.light.accentSecondary};
    --accent-tertiary: ${props.theme.light.accentTertiary};
    --accent-quaternary: ${props.theme.light.accentQuaternary};
    --accent-label-primary:  ${props.theme.light.accentLabelPrimary};
    --accent-label-secondary: ${props.theme.light.accentLabelSecondary};
    --accent-label-tertiary: ${props.theme.light.accentLabelTertiary};
    --accent-label-quaternary: ${props.theme.light.accentLabelQuaternary};

    /* Greys */
    --grey-1: ${props.theme.light.grey1};
    --grey-2: ${props.theme.light.grey2};
    --grey-3: ${props.theme.light.grey3};
    --grey-4: ${props.theme.light.grey4};
    --grey-5: ${props.theme.light.grey5};
    --grey-6: ${props.theme.light.grey6};

    /* Border Color */
    --border-color: ${props.theme.light.borderColor};

    /* Foreground */
    --foreground-radius: ${props.theme.light.foregroundRadius};

    /* Widget */
    --widget-radius: ${props.theme.light.widgetRadius};
    --widget-elevation: ${props.theme.light.widgetElevation};

    /* System Backgrounds */
    --sys-background-primary: ${props.theme.light.sysBackgroundPrimary};
    --sys-background-secondary: ${props.theme.light.sysBackgroundSecondary};

    /* Grouped Backgrounds */
    --group-background-primary: ${props.theme.light.groupBackgroundPrimary};
    --group-background-secondary: ${props.theme.light.groupBackgroundSecondary};

    /* Labels - Typography */
    --label-primary: ${props.theme.light.labelPrimary};
    --label-secondary :${props.theme.light.labelSecondary};
    --label-tertiary: ${props.theme.light.labelTertiary};
    --label-quaternary: ${props.theme.light.labelQuaternary};

    /* Buttons */
    --button-primary: ${props.theme.light.buttonPrimary};
    --button-hover: ${darken(0.1, props.theme.light.buttonPrimary)};
    --button-label: ${props.theme.light.buttonLabel};
    --button-radius: ${props.theme.light.buttonRadius};


    /* Divider */
    --divider-non-opaque: ${props.theme.light.dividerNonOpaque};
    --divider-opaque: ${props.theme.light.dividerOpaque};

    /* Fill */
    --fill-primary: ${props.theme.light.fillPrimary};
    --fill-secondary: ${props.theme.light.fillSecondary};
    --fill-tertiary: ${props.theme.light.fillTertiary};
    --fill-quaternary: ${props.theme.light.fillQuaternary};
    --fill-quaternary-hover: ${darken(0.5, props.theme.light.fillQuaternary)};

    /* Accent Fill */
    --accent-fill-primary: ${props.theme.light.accentFillPrimary};
    --accent-fill-secondary: ${props.theme.light.accentFillSecondary};
    --accent-fill-tertiary: ${props.theme.light.accentFillTertiary};
    --accent-fill-quaternary:${props.theme.light.accentFillQuaternary};

    /* Auto tint, Used depending on the background it sits on */
    --auto-tint-primary: #000000;
    --auto-tint-secondary: #ffffff;
  }

  .dark {
    /* Dark Theme */
    color-scheme: dark;

    --moonpay-brand: #ffffff;  
    --moonpay-body-background: #13131e;

    /* Accents */
    --accent-primary:  ${props.theme.dark.accentPrimary};
    --accent-secondary: ${props.theme.dark.accentSecondary};
    --accent-tertiary: ${props.theme.dark.accentTertiary};
    --accent-quaternary: ${props.theme.dark.accentQuaternary};
    --accent-label-primary: ${props.theme.dark.accentLabelPrimary};
    --accent-label-secondary: ${props.theme.dark.accentLabelSecondary};
    --accent-label-tertiary: ${props.theme.dark.accentLabelTertiary};
    --accent-label-quaternary: ${props.theme.dark.accentLabelQuaternary};

    /* Greys */
    --grey-1: ${props.theme.dark.grey1};
    --grey-2: ${props.theme.dark.grey2};
    --grey-3: ${props.theme.dark.grey3};
    --grey-4: ${props.theme.dark.grey4};
    --grey-5: ${props.theme.dark.grey5};
    --grey-6: ${props.theme.dark.grey6};

    /* Border Color */
    --border-color: ${props.theme.dark.borderColor};

    /* Foreground */
    --foreground-radius: ${props.theme.dark.foregroundRadius};

    /* Widget */
    --widget-radius: ${props.theme.dark.widgetRadius};
    --widget-elevation: ${props.theme.dark.widgetElevation};

    /* System Backgrounds */
    --sys-background-primary: ${props.theme.dark.sysBackgroundPrimary};
    --sys-background-secondary: ${props.theme.dark.sysBackgroundSecondary};

    /* Grouped Backgrounds */
    --group-background-primary: ${props.theme.dark.groupBackgroundPrimary};
    --group-background-secondary: ${props.theme.dark.groupBackgroundSecondary};

    /* Labels - Typography */
    --label-primary: ${props.theme.dark.labelPrimary};
    --label-secondary :${props.theme.dark.labelSecondary};
    --label-tertiary: ${props.theme.dark.labelTertiary};
    --label-quaternary: ${props.theme.dark.labelQuaternary};

    /* Buttons */
    --button-primary: ${props.theme.dark.buttonPrimary};
    --button-hover: ${darken(0.1, props.theme.dark.buttonPrimary)};
    --button-label: ${props.theme.dark.buttonLabel};
    --button-radius: ${props.theme.dark.buttonRadius};


    /* Divider */
    --divider-non-opaque: ${props.theme.dark.dividerNonOpaque};
    --divider-opaque: ${props.theme.dark.dividerOpaque};

    /* Fill */
    --fill-primary: ${props.theme.dark.fillPrimary};
    --fill-secondary: ${props.theme.dark.fillSecondary};
    --fill-tertiary: ${props.theme.dark.fillTertiary};
    --fill-quaternary: ${props.theme.dark.fillQuaternary};
    --fill-quaternary-hover: ${darken(0.5, props.theme.dark.fillQuaternary)};

    /* Accent Fill */
    --accent-fill-primary: ${props.theme.dark.accentFillPrimary};
    --accent-fill-secondary: ${props.theme.dark.accentFillSecondary};
    --accent-fill-tertiary: ${props.theme.dark.accentFillTertiary};
    --accent-fill-quaternary:${props.theme.dark.accentFillQuaternary};

    /* Auto tint, Used depending on the background it sits on */
    --auto-tint-primary: #ffffff;
    --auto-tint-secondary: #000000;

    .shadow-outline,
    .focus\\:shadow-outline:focus,
    .focus-within\\:shadow-outline:focus-within {
      box-shadow: 0 0 0 3px ${transparentize(
        0.5,
        props.theme.colors.darkThemePrimary,
      )};
    }

    .iti__selected-flag:focus {
      box-shadow: 0 0 0 3px ${transparentize(
        0.5,
        props.theme.colors.darkThemePrimary,
      )};
    }
  }

  .shadow-outline,
  .focus\\:shadow-outline:focus,
  .focus-within\\:shadow-outline:focus-within {
    box-shadow: 0 0 0 3px ${transparentize(
      0.5,
      props.theme.colors.lightThemePrimary,
    )};
  }

  .iti__selected-flag:focus {
    box-shadow: 0 0 0 3px ${transparentize(
      0.5,
      props.theme.colors.lightThemePrimary,
    )};
  }

  /* width */
  ::-webkit-scrollbar {
    width: 10px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: var(--sys-background-primary);
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: var(--grey-4);
    border-radius: 500px;
    border: 2px solid var(--sys-background-primary);
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: var(--grey-3);
  }

  .font-stretch-condensed {
    /* Font stretch of 60% and weight of 890 */
    font-variation-settings: "wdth" 60, 'wght' 890;
    /* The below will apply if the font does not support font-variation-settings */
    /* which means it is not a variable font */
    font-stretch: 60%;
    font-weight: 890;
  }

  @keyframes fadeIn {
    0% {
      opacity: 0;
    }

    100% {
      opacity: 1;
    }
  }
`;

interface GlobalStyleProviderProps {
  theme?: MoonPayTheme | MoonPayThemeBase;
  children: ReactNode;
}

const GlobalStyleProvider: FC<GlobalStyleProviderProps> = ({
  theme,
  children,
}) => {
  return (
    <>
      <style>{createGlobalStyle({ theme: theme as MoonPayTheme })}</style>
      {children}
    </>
  );
};

export default GlobalStyleProvider;
