import { FunctionComponent, ReactElement, SVGProps } from 'react';

interface MoonPayIconProps extends SVGProps<SVGElement> {
  size?: number;
  className?: string;
}

const MoonPayIcon: FunctionComponent<MoonPayIconProps> = ({
  size = 15,
  className,
  style,
}): ReactElement => {
  return (
    <svg
      width={size}
      height={size}
      viewBox={`0 0 ${size} ${size}`}
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
      style={style}
    >
      <path
        d="M12.6556 4.87993C13.0887 4.87993 13.5121 4.75149 13.8722 4.51085C14.2324 4.27021 14.5131 3.92819 14.6788 3.52803C14.8446 3.12786 14.8879 2.68753 14.8034 2.26272C14.7189 1.83791 14.5104 1.4477 14.2041 1.14143C13.8978 0.835154 13.5076 0.626581 13.0828 0.54208C12.658 0.45758 12.2177 0.500949 11.8175 0.666702C11.4173 0.832455 11.0753 1.11315 10.8347 1.47329C10.594 1.83342 10.4656 2.25683 10.4656 2.68996C10.4656 2.97756 10.5222 3.26234 10.6323 3.52805C10.7423 3.79375 10.9036 4.03518 11.107 4.23854C11.3103 4.4419 11.5518 4.60321 11.8175 4.71326C12.0832 4.82331 12.368 4.87994 12.6556 4.87993ZM5.94058 14.743C4.88482 14.743 3.85276 14.4299 2.97492 13.8433C2.09709 13.2568 1.4129 12.4231 1.00887 11.4477C0.604851 10.4723 0.499141 9.399 0.705111 8.36352C0.911081 7.32804 1.41948 6.37689 2.16602 5.63036C2.91256 4.88382 3.86371 4.37542 4.89919 4.16945C5.93467 3.96349 7.00797 4.0692 7.98337 4.47323C8.95876 4.87725 9.79245 5.56144 10.379 6.43928C10.9655 7.31712 11.2786 8.34918 11.2786 9.40494C11.2786 10.1059 11.1406 10.8001 10.8723 11.4477C10.6041 12.0954 10.2109 12.6839 9.71518 13.1795C9.21949 13.6752 8.63103 14.0684 7.98338 14.3367C7.33573 14.6049 6.64159 14.743 5.94058 14.743Z"
        fill="currentColor"
      />
    </svg>
  );
};

export default MoonPayIcon;
