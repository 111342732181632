import { FunctionComponent, ReactElement } from 'react';

const AccessCollectionRequestIcon: FunctionComponent = (): ReactElement => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" rx="22.5" fill="#7D00FF" fillOpacity="0.08" />
    <path
      d="M21 27.6667V44.3333C21 46.1743 22.4924 47.6667 24.3333 47.6667H47.6667C49.5076 47.6667 51 46.1743 51 44.3333V31C51 29.1591 49.5076 27.6667 47.6667 27.6667H37.6667L34.3333 24.3333H24.3333C22.4924 24.3333 21 25.8257 21 27.6667Z"
      stroke="#7D00FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default AccessCollectionRequestIcon;
