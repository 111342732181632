import en from './locales/en.json';

// Use a proxy to get the dotted path of the key.
//
// @example
// console.log(strings.a.b.c.d); // 'a.b.c.d'
// console.log(strings.widget.title); // 'widget.title'
//
// When you access a property that's an object behind a proxy,
// this handler will be called.
// Specifically, the get() method will be called.
// It's like a property getter.
//
// If accessed property is an object, return a new proxy with the
// path of the property appended to the path of the parent object.
// If accessed property is not an object, return the path of the
// property appended to the path of the parent object joined with dot.
//
// @example
// const targetObject = {
//   a: {
//     b: {
//       c: {
//         d: 'Hello World',
//       },
//     },
//   },
// };
// const proxy = new Proxy(targetObject, createProxyHandler());
// console.log(proxy.a); // This is a proxy
// console.log(proxy.a.b); // This is a proxy
// console.log(proxy.a.b.c); // This is a proxy
// console.log(proxy.a.b.c.d); // 'a.b.c.d' because d is not an object but not array (or null)
//
// @example
// const targetObject = {
//   a: ['b', 'c', 'd']
// };
// const proxy = new Proxy(targetObject, createProxyHandler());
// console.log(proxy.a); // This is a proxy
// console.log(proxy.a); // 'a' because it is an array
const createProxyHandler = (path = [] as string[]) => ({
  get(target: any, key: string): any {
    if (
      typeof target[key] === 'object' &&
      target[key] !== null &&
      !Array.isArray(target[key])
    ) {
      return new Proxy(target[key], createProxyHandler(path.concat(key)));
    }

    if (typeof target[key] === 'undefined') {
      return undefined;
    }

    return [...path, key].join('.');
  },
});

export default new Proxy<typeof en>(en, createProxyHandler());
