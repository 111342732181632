import { FunctionComponent, ReactElement } from 'react';

const ArrowSmDownIcon: FunctionComponent = (): ReactElement => (
  <svg
    width="21"
    height="20"
    viewBox="0 0 21 20"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M15.0406 10.2929C15.4311 10.6834 15.4311 11.3166 15.0406 11.7071L11.0406 15.7071C10.6501 16.0976 10.0169 16.0976 9.62639 15.7071L5.62639 11.7071C5.23586 11.3166 5.23586 10.6834 5.62639 10.2929C6.01691 9.90237 6.65008 9.90237 7.0406 10.2929L9.3335 12.5858V5C9.3335 4.44772 9.78121 4 10.3335 4C10.8858 4 11.3335 4.44772 11.3335 5L11.3335 12.5858L13.6264 10.2929C14.0169 9.90237 14.6501 9.90237 15.0406 10.2929Z"
      fill="white"
    />
  </svg>
);

export default ArrowSmDownIcon;
