import { FunctionComponent, ReactNode } from 'react';
import Text from '../Text';
import Tooltip from '../Tooltip';
import { Tip } from '../Tooltip/types';

export interface SummaryRowProps {
  title: ReactNode;
  value: ReactNode;
  info?: Tip;
  titleClassName?: string;
  valueClassName?: string;
}

const SummaryRow: FunctionComponent<SummaryRowProps> = ({
  title,
  value,
  titleClassName,
  valueClassName,
  info,
}) => {
  return (
    <div className="flex justify-between w-full">
      <span className="flex items-center">
        <Text variant="subhead" as="p" className={titleClassName}>
          {title}
        </Text>
        {info && (
          <Tooltip
            className="text-center"
            iconClassName="ml-1 text-label-secondary"
            onShown={info.onShown}
          >
            {info.content}
          </Tooltip>
        )}
      </span>
      <Text variant="subhead" as="p" className={valueClassName}>
        {value}
      </Text>
    </div>
  );
};

export default SummaryRow;
