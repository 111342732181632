import { SecureWalletUxMode } from '@moonpay/login-common';
import { FC } from 'react';
import { PromptContainer } from 'src/components/PromptContainer';

interface SecureWalletProps {
  uxMode: SecureWalletUxMode;
  promptRef: React.RefObject<HTMLDivElement>;
}

const SecureWallet: FC<SecureWalletProps> = ({
  uxMode,
  promptRef,
}: SecureWalletProps) => {
  switch (uxMode) {
    case SecureWalletUxMode.NoUx:
      return <></>;

    case SecureWalletUxMode.Prompt:
      return <PromptContainer ref={promptRef} />;

    default:
      return null;
  }
};

export default SecureWallet;
