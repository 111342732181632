import { FunctionComponent, ReactElement } from 'react';

const NetworkNotSupportedIcon: FunctionComponent = (): ReactElement => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" rx="22.5" fill="#D52A3E" fillOpacity="0.08" />
    <path
      d="M36 31V34.3334M36 41H36.0167M50.3632 25.9739C50.0221 25.9912 49.6787 26 49.3333 26C44.2109 26 39.5383 24.0742 35.9999 20.9072C32.4615 24.0741 27.789 25.9997 22.6667 25.9997C22.3213 25.9997 21.978 25.991 21.6369 25.9737C21.2212 27.5797 21 29.264 21 31C21 40.3192 27.3739 48.1497 36 50.3699C44.6261 48.1497 51 40.3192 51 31C51 29.2641 50.7788 27.5799 50.3632 25.9739Z"
      stroke="#D52A3E"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default NetworkNotSupportedIcon;
