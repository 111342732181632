import i18n from 'i18next';
import LanguageDetector from 'i18next-browser-languagedetector';
import resourcesToBackend from 'i18next-resources-to-backend';
import { initReactI18next } from 'react-i18next';
import en from './locales/en.json';
import strings from './strings';

const isTest = process.env.NODE_ENV === 'test';

i18n.use(LanguageDetector).use(initReactI18next);

i18n.use(
  // lazy load the translations
  resourcesToBackend((language, _, callback) => {
    if (language === 'en') {
      callback(null, en);
      return;
    }

    import(`./locales/${language}.json`)
      .then((resources) => {
        callback(null, resources);
      })
      .catch((error) => {
        callback(error, null);
      });
  }),
);

i18n.init({
  debug: false,
  fallbackLng: 'en',
  interpolation: {
    escapeValue: false,
  },
  react: {
    useSuspense: false,
  },
  ...(isTest ? { resources: { en: { translation: en } } } : {}),
});

export default i18n;
export { strings };
