class TimeoutError extends Error {
  constructor() {
    super('Timeout');
  }
}

/**
 * Wait a given amount of milliseconds.
 *
 * Returns a Promise that will reject after `delay`.
 */
export const throwAfter = (delay: number): Promise<never> => {
  return new Promise((_, reject) => {
    setTimeout(() => {
      reject(new TimeoutError());
    }, delay);
  });
};
