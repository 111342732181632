import { Text } from '@moonpay-widget/ui-kit';
import MoonPayIcon from '@moonpay-widget/ui-kit/src/ContainerFooter/Logo';
import { FC } from 'react';
import CopyCryptoAddressField from 'src/components/WalletAddressComponent/CopyCryptoAddressField';
import { formatWalletAddress } from 'src/utils/formatters';

interface WalletAddressComponentProps {
  leftLabel: string;
  leftValue: string;
  rightLabel: string;
  rightValue: string;
}

const WalletAddressComponent: FC<WalletAddressComponentProps> = ({
  leftLabel,
  leftValue,
  rightLabel,
  rightValue,
}) => {
  return (
    <div className="flex justify-between p-4 mb-3 bg-system-background-secondary rounded-xxl">
      <div className="float-left w-1/2">
        <Text variant="footnote" as="div" className="text-label-secondary">
          {leftLabel}
        </Text>

        <div className="flex items-center mt-1 text-left">
          {leftValue ? (
            <Text variant="callout" className="font-light">
              {formatWalletAddress({ address: leftValue })}
            </Text>
          ) : (
            <>
              <MoonPayIcon
                style={{
                  color: 'var(--moonpay-brand)',
                }}
                className="mr-2"
              />
              <Text variant="callout" className="font-light">
                MoonPay Account
              </Text>
            </>
          )}
        </div>
      </div>
      <div className="flex flex-col items-end justify-center w-1/2 text-right">
        <Text variant="footnote" as="div" className="text-label-secondary">
          {rightLabel}
        </Text>
        <div className="flex self-end justify-end mt-1 font-light w-36 text-callout leading-callout tracking-callout">
          <CopyCryptoAddressField float="end" text={rightValue} />
        </div>
      </div>
    </div>
  );
};

export default WalletAddressComponent;
