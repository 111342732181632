import ArrowCircleRightIcon from '@heroicons/react/outline/ArrowCircleRightIcon';
import XCircleIcon from '@heroicons/react/outline/XCircleIcon';
import { CSSProperties, FC, FunctionComponent } from 'react';
import Icon from '../Icon';
import Text from '../Text';
import './CurrencyXCurrency.css';

interface CurrencyXCurrencyProps {
  currencyFrom: {
    name: string;
    icon: FunctionComponent;
  };
  currencyTo: {
    name: string;
    icon: FunctionComponent;
  };
  isNotAllowed?: boolean;
}

const getDotStyle = (opacity: number): CSSProperties => ({
  background: 'var(--accent-primary)',
  height: '0.25rem',
  width: '0.25rem',
  margin: '0 0.25rem',
  borderRadius: '50%',
  opacity: opacity / 100,
});

const CurrencyXCurrency: FC<CurrencyXCurrencyProps> = ({
  currencyFrom,
  currencyTo,
  isNotAllowed = false,
}) => {
  return (
    <div className="flex items-center justify-center w-full">
      <div className="flex flex-col items-center mx-1 mb-6 border-divider-nonOpaque">
        <Icon
          className="m-auto"
          icon={currencyFrom.icon}
          iconAlt={currencyFrom.name}
        />
        <Text
          variant="footnote"
          as="div"
          className="mt-2 text-center text-label-primary"
        >
          {currencyFrom.name}
        </Text>
      </div>
      {!isNotAllowed ? (
        <div className="flex justify-center">
          {Array(6)
            .fill(0)
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index} style={getDotStyle(40)} />
            ))}
          <ArrowCircleRightIcon className="CurrencyXCurrency_ArrowCircleRightIcon" />
          {Array(6)
            .fill(0)
            .map((_, index) => (
              // eslint-disable-next-line react/no-array-index-key
              <span key={index} style={getDotStyle(40)} />
            ))}
        </div>
      ) : (
        <div className="flex justify-center">
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{ background: '#FF0000', ...getDotStyle(40) }}
              />
            ))}
          <XCircleIcon className="CurrencyXCurrency_XCircleIcon" />
          {Array(6)
            .fill(0)
            .map((_, index) => (
              <span
                // eslint-disable-next-line react/no-array-index-key
                key={index}
                style={{ background: '#FF0000', ...getDotStyle(40) }}
              />
            ))}
        </div>
      )}

      <div className="flex flex-col items-center mx-1 mb-6 border-divider-nonOpaque">
        <Icon icon={currencyTo.icon} iconAlt={currencyTo.name} />
        <Text
          variant="footnote"
          className="mt-2 text-center text-label-primary"
        >
          {currencyTo.name}
        </Text>
      </div>
    </div>
  );
};
export default CurrencyXCurrency;
