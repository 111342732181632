import ExternalLinkIcon from '@heroicons/react/outline/ExternalLinkIcon';
import { FC, useCallback, useEffect, useRef, useState } from 'react';
import Button from '../Button';
import Checkbox from '../Checkbox';
import ListItem from '../ListItem';
import SlideInModal from '../SlideInModal';
import Text from '../Text';

export interface TermsAndConditionsConfig {
  title: string;
  link: string;
  onAcceptPress?: () => void;
}

export interface TermsAndConditionsBannerProps {
  title: string;
  subtitle?: string;
  buttonText: string;
  scrollText?: string;
  termsAndConditions?: TermsAndConditionsConfig[];
  onClose?: () => void;
  optIns?: {
    title: string;
    value: boolean;
    subtitle?: string;
    onChange: (value: boolean) => void;
  }[];
  onAcceptPress?: () => void;
  footer?: string;
  warning?: string;
  isOpen?: boolean;
}

const TermsAndConditionsBanner: FC<TermsAndConditionsBannerProps> = ({
  title,
  subtitle,
  buttonText,
  scrollText,
  termsAndConditions,
  optIns,
  onAcceptPress,
  onClose,
  footer,
  warning,
  isOpen = false,
}) => {
  const [isButtonDisabled, setIsButtonDisabled] = useState(true);
  const scrollContainerRef = useRef<HTMLDivElement>(null);

  const handleScroll = useCallback(() => {
    const element = scrollContainerRef.current;
    if (element) {
      const { scrollTop, scrollHeight, clientHeight } = element;
      const isBottom =
        Math.round(scrollTop + clientHeight) >= scrollHeight && isOpen;
      setIsButtonDisabled(!isBottom);
    }
  }, [isOpen]);

  useEffect(() => {
    const element = scrollContainerRef.current;
    if (element) {
      handleScroll();
      element.addEventListener('scroll', handleScroll);
      return () => element.removeEventListener('scroll', handleScroll);
    }
    return undefined;
  }, [handleScroll]);

  const handleSubmit = () => {
    termsAndConditions?.forEach((term) => {
      term.onAcceptPress?.();
    });
    onAcceptPress?.();
  };

  return (
    <SlideInModal
      isOpen={isOpen}
      key="terms-and-conditions"
      headerTitle={title}
      onClose={onClose}
      style={{ borderTopLeftRadius: '1rem', borderTopRightRadius: '1rem' }}
      isHeaderCentered
    >
      {!onClose && (
        <Text className="text-center" variant="title2">
          {title}
        </Text>
      )}
      <Text className="text-center text-label-secondary" variant="footnote">
        {subtitle}
      </Text>
      <div className="mt-2 overflow-auto h-60" ref={scrollContainerRef}>
        {termsAndConditions?.map((term) => (
          <ListItem
            key={term.title}
            className="my-2 bg-system-background-secondary rounded-xxl"
            as="div"
            label={term.title}
            rightContent={
              <a href={term.link} target="_blank" rel="noreferrer">
                <ExternalLinkIcon className="h-8 p-1 mr-2 text-label-tertiary" />
              </a>
            }
          />
        ))}
        {optIns?.map((optIn) => (
          <ListItem
            key={optIn.title}
            as="div"
            className="my-2 bg-system-background-secondary rounded-xxl"
            label={optIn.title}
            description={optIn.subtitle}
            descriptionClassName="text-label-secondary"
            onClick={() => {
              optIn.onChange(!optIn.value);
            }}
            rightContent={
              <Checkbox
                key={optIn.title}
                name={optIn.title}
                id={optIn.title}
                checked={optIn.value}
                value={optIn.title}
                className="h-8"
                onChange={() => {
                  //
                }}
                onClick={(e) => {
                  e.preventDefault();
                  e.stopPropagation();
                }}
              />
            }
          />
        ))}
      </div>

      {warning && (
        <Text
          className="pb-2 text-center text-accent-label-tertiary"
          variant="footnote"
        >
          {warning}
        </Text>
      )}
      {footer && (
        <Text className="text-center" variant="footnote">
          {footer}
        </Text>
      )}
      <Button disabled={isButtonDisabled} onClick={handleSubmit}>
        {isButtonDisabled ? scrollText : buttonText}
      </Button>
    </SlideInModal>
  );
};
export default TermsAndConditionsBanner;
