var __extends = (this && this.__extends) || (function () {
    var extendStatics = function (d, b) {
        extendStatics = Object.setPrototypeOf ||
            ({ __proto__: [] } instanceof Array && function (d, b) { d.__proto__ = b; }) ||
            function (d, b) { for (var p in b) if (Object.prototype.hasOwnProperty.call(b, p)) d[p] = b[p]; };
        return extendStatics(d, b);
    };
    return function (d, b) {
        if (typeof b !== "function" && b !== null)
            throw new TypeError("Class extends value " + String(b) + " is not a constructor or null");
        extendStatics(d, b);
        function __() { this.constructor = d; }
        d.prototype = b === null ? Object.create(b) : (__.prototype = b.prototype, new __());
    };
})();
var EthProviderRpcError = /** @class */ (function (_super) {
    __extends(EthProviderRpcError, _super);
    function EthProviderRpcError(code, data) {
        var _this = _super.call(this) || this;
        _this.code = code;
        _this.data = data;
        _this.message = data;
        return _this;
    }
    EthProviderRpcError.prototype.toString = function () {
        return "".concat(this.data, " (").concat(this.code, ")");
    };
    EthProviderRpcError.prototype.toObject = function () {
        return {
            code: this.code,
            data: this.data,
            message: this.message,
        };
    };
    return EthProviderRpcError;
}(Error));
export { EthProviderRpcError };
export var EthProviderRpcErrorCode;
(function (EthProviderRpcErrorCode) {
    EthProviderRpcErrorCode[EthProviderRpcErrorCode["UserRejectedRequest"] = 4001] = "UserRejectedRequest";
    EthProviderRpcErrorCode[EthProviderRpcErrorCode["Unauthorized"] = 4100] = "Unauthorized";
    EthProviderRpcErrorCode[EthProviderRpcErrorCode["UnsupportedMethod"] = 4200] = "UnsupportedMethod";
    EthProviderRpcErrorCode[EthProviderRpcErrorCode["Disconnected"] = 4900] = "Disconnected";
    EthProviderRpcErrorCode[EthProviderRpcErrorCode["ChainDisconnected"] = 4901] = "ChainDisconnected";
    EthProviderRpcErrorCode[EthProviderRpcErrorCode["IncorrectArgument"] = 32602] = "IncorrectArgument";
})(EthProviderRpcErrorCode || (EthProviderRpcErrorCode = {}));
export var EthEvent;
(function (EthEvent) {
    EthEvent["message"] = "message";
    EthEvent["connect"] = "connect";
    EthEvent["disconnect"] = "disconnect";
    EthEvent["chainChanged"] = "chainChanged";
    EthEvent["accountsChanged"] = "accountsChanged";
    EthEvent["data"] = "data";
})(EthEvent || (EthEvent = {}));
