import { FunctionComponent } from 'react';

const SolanaIcon: FunctionComponent = () => {
  return (
    <svg viewBox="0 0 32 32" xmlns="http://www.w3.org/2000/svg">
      <defs>
        <filter
          id="filter0_ii"
          x={-2}
          y={-2}
          width={357}
          height={289.593}
          filterUnits="userSpaceOnUse"
          colorInterpolationFilters="sRGB"
        >
          <feFlood floodOpacity={0} result="BackgroundImageFix" />
          <feBlend in="SourceGraphic" in2="BackgroundImageFix" result="shape" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={-2} dy={2} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 0.25 0" />
          <feBlend in2="shape" result="effect1_innerShadow" />
          <feColorMatrix
            in="SourceAlpha"
            values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
            result="hardAlpha"
          />
          <feOffset dx={2} dy={-2} />
          <feGaussianBlur stdDeviation={1} />
          <feComposite in2="hardAlpha" operator="arithmetic" k2={-1} k3={1} />
          <feColorMatrix values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0" />
          <feBlend in2="effect1_innerShadow" result="effect2_innerShadow" />
        </filter>
        <linearGradient
          id="paint0_linear"
          x1={360.8}
          y1={-12.867}
          x2={158.22}
          y2={434.979}
          gradientUnits="userSpaceOnUse"
        >
          <stop offset={0} stopColor="#02FFAD" />
          <stop offset={0.5} stopColor="#79ABD2" />
          <stop offset={1} stopColor="#F71DF4" />
        </linearGradient>
      </defs>
      <g
        filter="url(#filter0_ii)"
        transform="matrix(1, 0, 0, 1, 78.340355, 83.182838)"
      >
        <mask
          id="a"
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={353}
          height={286}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M302.21 67.604a11.22 11.22 0 01-7.985 3.35H11.315c-10 0-15.065-12.193-8.074-19.435L49.647 3.442A11.22 11.22 0 0157.72 0h283.965c10.06 0 15.099 12.321 7.985 19.527l-47.46 48.077zm0 214.722a11.363 11.363 0 01-7.985 3.267H11.315c-10 0-15.065-11.889-8.074-18.95l46.406-46.875a11.362 11.362 0 018.073-3.356h283.965c10.06 0 15.099 12.013 7.985 19.039l-47.46 46.875zm-7.985-174.12c2.995 0 5.867 1.175 7.985 3.267l47.46 46.875c7.114 7.026 2.075 19.039-7.985 19.039H57.72c-3.038 0-5.949-1.21-8.073-3.356L3.24 127.156c-6.99-7.061-1.925-18.95 8.074-18.95h282.91z"
            fill="#00FFAD"
          />
        </mask>
        <g mask="url(#a)" />
      </g>
      <circle id="Ellipse_1" data-name="Ellipse 1" cx={16} cy={16} r={16} />
      <g
        filter="url(#filter0_ii)"
        transform="matrix(0.060855, 0, 0, 0.060855, 5.231584, 7.360815)"
      >
        <mask
          id="mask-1"
          maskUnits="userSpaceOnUse"
          x={0}
          y={0}
          width={353}
          height={286}
        >
          <path
            fillRule="evenodd"
            clipRule="evenodd"
            d="M302.21 67.604a11.22 11.22 0 01-7.985 3.35H11.315c-10 0-15.065-12.193-8.074-19.435L49.647 3.442A11.22 11.22 0 0157.72 0h283.965c10.06 0 15.099 12.321 7.985 19.527l-47.46 48.077zm0 214.722a11.363 11.363 0 01-7.985 3.267H11.315c-10 0-15.065-11.889-8.074-18.95l46.406-46.875a11.362 11.362 0 018.073-3.356h283.965c10.06 0 15.099 12.013 7.985 19.039l-47.46 46.875zm-7.985-174.12c2.995 0 5.867 1.175 7.985 3.267l47.46 46.875c7.114 7.026 2.075 19.039-7.985 19.039H57.72c-3.038 0-5.949-1.21-8.073-3.356L3.24 127.156c-6.99-7.061-1.925-18.95 8.074-18.95h282.91z"
            fill="#00FFAD"
          />
        </mask>
        <g mask="url(#mask-1)">
          <path d="M-156-25h608v430h-608V-25z" fill="url(#paint0_linear)" />
        </g>
      </g>
    </svg>
  );
};

export default SolanaIcon;
