import clsx from 'clsx';
import { TextVariant } from './types';

interface getClassNamesForVariantProps {
  variant: TextVariant;
  bold?: boolean;
}

export const getClassNamesForVariant = ({
  variant,
  bold,
}: getClassNamesForVariantProps): string => {
  switch (variant) {
    case 'largeTitle':
      return clsx(
        'text-largeTitle leading-largeTitle',
        bold ? 'font-semibold' : 'font-medium',
      );

    case 'title1':
      return clsx(
        'text-title1 leading-title1',
        bold ? 'font-semibold' : 'font-medium',
      );

    case 'title2':
      return clsx(
        'text-title2 leading-title2',
        bold ? 'font-semibold' : 'font-medium',
      );

    case 'title3':
      return clsx(
        'text-title3 leading-title3',
        bold ? 'font-semibold' : 'font-medium',
      );

    case 'headline':
      return clsx(
        'text-headline leading-headline tracking-headline',
        bold ? 'font-semibold' : 'font-regular',
      );

    case 'body':
      return clsx(
        'text-body leading-body tracking-body',
        bold ? 'font-medium' : 'font-regular',
      );

    case 'callout':
      return clsx(
        'text-callout leading-callout tracking-callout',
        bold ? 'font-medium' : 'font-regular',
      );

    case 'subhead':
      return clsx(
        'text-subhead leading-subhead tracking-subhead',
        bold ? 'font-medium' : 'font-regular',
      );

    case 'footnote':
      return clsx(
        'text-footnote leading-footnote tracking-footnote',
        bold ? 'font-medium' : 'font-regular',
      );

    case 'caption1':
      return clsx(
        'text-caption1 leading-caption1',
        bold ? 'font-medium' : 'font-regular',
      );

    case 'caption2':
      return clsx(
        'text-caption2 leading-caption2',
        bold ? 'font-medium' : 'font-regular',
      );

    default:
      return 'text-body leading-body';
  }
};
