import ChevronDownIcon from '@heroicons/react/solid/ChevronDownIcon';
import React, {
  ComponentProps,
  PropsWithChildren,
  ReactNode,
  useEffect,
  useState,
} from 'react';
import Label from '../Label';

export interface SummaryTableProps {
  label?: ReactNode;
  subLabel?: ReactNode;
  header: ReactNode;
  className?: string;
  openByDefault?: boolean;
  onOpen?: () => void;
}

interface AccordionProps {
  isOpen: boolean;
}

const ArrowContainer = ({
  children,
  isOpen,
  ...props
}: ComponentProps<'div'> & AccordionProps) => (
  <div
    {...props}
    style={{
      transform: isOpen ? 'rotate(180deg)' : 'rotate(0deg)',
      transition: 'transform 0.2s ease-in',
      transformOrigin: 'center',
    }}
  >
    {children}
  </div>
);

const SummaryContent = ({
  children,
  isOpen,
  ...props
}: ComponentProps<'div'> & AccordionProps) => (
  <div
    {...props}
    style={{
      transition: !isOpen
        ? 'max-height 0.5s cubic-bezier(0, 1, 0, 1)'
        : 'max-height 0.5s ease-in',
      willChange: 'max-height',
      maxHeight: !isOpen ? 0 : '100vh',
      overflow: 'hidden',
      width: '100%',
    }}
  >
    {children}
  </div>
);

const Divider = ({
  isOpen,
  ...props
}: ComponentProps<'hr'> & AccordionProps) => (
  <hr
    {...props}
    style={{
      transition: 'width 0.3s ease-out, opacity 0.3s ease-in',
      willChange: 'width, opacity',
      opacity: isOpen ? 1 : 0,
      width: isOpen ? '100%' : '0%',
      height: '1px',
      borderTopWidth: 0,
    }}
  />
);

const UpdatedSummaryTable = ({
  label = '',
  subLabel,
  header,
  children,
  className,
  openByDefault = false,
  onOpen,
}: PropsWithChildren<SummaryTableProps>) => {
  const [isSummaryOpen, setIsSummaryOpen] = useState(openByDefault);

  useEffect(() => {
    /**
     * In case of Zero Hash quote for NY customers,
     * tt's possible the quote is not fetched yet
     * and the provider is ZeroHash.
     * That means the openByDefault might become true after the component
     * rendered. This will ensure the component is opened.
     */
    if (openByDefault) {
      setIsSummaryOpen(true);
    }
  }, [openByDefault]);

  useEffect(() => {
    if (isSummaryOpen) {
      onOpen?.();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isSummaryOpen]);

  const hasChildren =
    React.Children.toArray(children).filter(Boolean).length > 0;

  const handleClick = () => {
    setIsSummaryOpen(!isSummaryOpen);
  };

  return (
    <div className={className}>
      <div className="flex items-center justify-between">
        {label && <Label>{label}</Label>}
        {subLabel}
      </div>
      <div className="border bg-system-background-secondary rounded-foreground border-main">
        <div
          className="flex items-center justify-between px-4 py-4 rounded-foreground focus:outline-none focus:shadow-outline"
          role="button"
          onKeyDown={(e) => e.key === 'Enter' && handleClick()}
          onClick={handleClick}
          tabIndex={0}
          data-testid="summary-table-header"
        >
          {header}
          {hasChildren && (
            <ArrowContainer
              isOpen={isSummaryOpen}
              data-testid="summary-chevron"
            >
              <ChevronDownIcon className="w-6 h-6 text-label-tertiary" />
            </ArrowContainer>
          )}
        </div>
        {hasChildren && (
          <SummaryContent
            isOpen={isSummaryOpen}
            className="px-5"
            data-testid="updated-summary-content"
          >
            <Divider
              isOpen={isSummaryOpen}
              className="mb-4 bg-divider-nonOpaque border-divider-nonOpaque"
              data-testid="summary-divider"
            />
            <div className="pb-4 space-y-3">{children}</div>
          </SummaryContent>
        )}
      </div>
    </div>
  );
};

export default UpdatedSummaryTable;
