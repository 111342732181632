import { FunctionComponent, ReactElement } from 'react';

const ConnectionRequestIcon: FunctionComponent = (): ReactElement => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" rx="22.5" fill="#7D00FF" fillOpacity="0.08" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M49.1694 22.8306C46.7286 20.3898 42.7714 20.3898 40.3306 22.8306L32.8306 30.3306C30.3898 32.7714 30.3898 36.7286 32.8306 39.1694C33.3487 39.6875 33.933 40.0942 34.555 40.3914C35.178 40.689 35.4417 41.4352 35.1441 42.0581C34.8466 42.681 34.1004 42.9448 33.4774 42.6472C32.6028 42.2294 31.7844 41.6588 31.0628 40.9372C27.6457 37.5201 27.6457 31.9799 31.0628 28.5628L38.5628 21.0628C41.9799 17.6457 47.5201 17.6457 50.9372 21.0628C54.3543 24.4799 54.3543 30.0201 50.9372 33.4372L48.0089 36.3655C47.5207 36.8536 46.7293 36.8536 46.2411 36.3655C45.753 35.8773 45.753 35.0859 46.2411 34.5977L49.1694 31.6694C51.6102 29.2286 51.6102 25.2714 49.1694 22.8306ZM36.8559 29.9419C37.1534 29.319 37.8997 29.0552 38.5226 29.3528C39.3972 29.7706 40.2156 30.3412 40.9372 31.0628C44.3543 34.4799 44.3543 40.0201 40.9372 43.4372L33.4372 50.9372C30.0201 54.3543 24.4799 54.3543 21.0628 50.9372C17.6457 47.5201 17.6457 41.9799 21.0628 38.5628L23.9911 35.6345C24.4793 35.1464 25.2707 35.1464 25.7589 35.6345C26.247 36.1227 26.247 36.9141 25.7589 37.4023L22.8306 40.3306C20.3898 42.7714 20.3898 46.7286 22.8306 49.1694C25.2714 51.6102 29.2286 51.6102 31.6694 49.1694L39.1694 41.6694C41.6102 39.2286 41.6102 35.2714 39.1694 32.8306C38.6513 32.3125 38.067 31.9058 37.445 31.6086C36.822 31.311 36.5583 30.5648 36.8559 29.9419Z"
      fill="#7D00FF"
      stroke="#7D00FF"
      strokeLinecap="round"
    />
  </svg>
);

export default ConnectionRequestIcon;
