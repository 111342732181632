import { FunctionComponent, ReactElement } from 'react';

const FillNFTOrderIcon: FunctionComponent = (): ReactElement => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" rx="22.5" fill="#7D00FF" fillOpacity="0.08" />
    <path
      d="M42.6665 34.3333V27.6667C42.6665 23.9848 39.6817 21 35.9998 21C32.3179 21 29.3332 23.9848 29.3332 27.6667V34.3333M24.3332 31H47.6665L49.3332 51H22.6665L24.3332 31Z"
      stroke="#7D00FF"
      strokeWidth="3"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </svg>
);

export default FillNFTOrderIcon;
