import { ComponentProps, FunctionComponent } from 'react';
import { ColorSchemeVariant } from 'src/wallet/types/ColorSchemeVariant';

interface MoonpayStaticLogoWithoutTextProps extends ComponentProps<'svg'> {
  className?: string;
  colorScheme?: string;
}

const MoonpayStaticLogoWithoutTextBase: FunctionComponent<
  MoonpayStaticLogoWithoutTextProps
> = ({ className, colorScheme }) => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 64 64"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className={className}
    >
      <rect
        width="64"
        height="64"
        fill={colorScheme === ColorSchemeVariant.Dark ? '#1c1c1e' : 'white'}
      />
      <path
        d="M44.3782 25.1187C45.4653 25.1187 46.5281 24.7963 47.432 24.1923C48.336 23.5883 49.0405 22.7298 49.4566 21.7254C49.8726 20.721 49.9815 19.6157 49.7694 18.5495C49.5573 17.4832 49.0338 16.5037 48.265 15.735C47.4963 14.9662 46.5168 14.4427 45.4506 14.2306C44.3843 14.0185 43.279 14.1274 42.2746 14.5434C41.2702 14.9595 40.4117 15.664 39.8077 16.568C39.2037 17.4719 38.8813 18.5347 38.8813 19.6218C38.8813 20.3437 39.0235 21.0585 39.2997 21.7254C39.5759 22.3924 39.9808 22.9983 40.4912 23.5088C41.0017 24.0192 41.6077 24.4241 42.2746 24.7003C42.9415 24.9766 43.6563 25.1187 44.3782 25.1187ZM27.5235 49.875C24.8736 49.875 22.2831 49.0892 20.0797 47.6169C17.8763 46.1447 16.159 44.0521 15.1449 41.6039C14.1308 39.1556 13.8655 36.4616 14.3825 33.8625C14.8994 31.2635 16.1755 28.8761 18.0494 27.0023C19.9232 25.1284 22.3106 23.8524 24.9096 23.3354C27.5087 22.8184 30.2027 23.0837 32.6509 24.0978C35.0992 25.112 37.1918 26.8293 38.664 29.0327C40.1362 31.236 40.922 33.8265 40.922 36.4765C40.9221 38.236 40.5756 39.9783 39.9022 41.604C39.2289 43.2296 38.242 44.7066 36.9978 45.9508C35.7536 47.195 34.2766 48.1819 32.651 48.8552C31.0254 49.5285 29.2831 49.8751 27.5235 49.875Z"
        fill={colorScheme === ColorSchemeVariant.Dark ? 'white' : '#1c1c1e'}
      />
    </svg>
  );
};

export const MoonpayStaticLogoWithoutText: FunctionComponent<
  MoonpayStaticLogoWithoutTextProps
> = ({ className }) => {
  return MoonpayStaticLogoWithoutTextBase({
    className,
    colorScheme: ColorSchemeVariant.Light,
  });
};

export const MoonpayStaticLogoWithoutTextDark: FunctionComponent<
  MoonpayStaticLogoWithoutTextProps
> = ({ className }) => {
  return MoonpayStaticLogoWithoutTextBase({
    className,
    colorScheme: ColorSchemeVariant.Dark,
  });
};
