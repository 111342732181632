import { useEffect, useState } from 'react';
import {
  ThemeConfigV1Base,
  ThemeConfigV1Response,
} from 'theming/providers/ThemeProvider/types';

export const useWidgetThemeFromDashboard = () => {
  const [themeConfiguration, setThemeConfiguration] = useState<
    ThemeConfigV1Response | ThemeConfigV1Base | null
  >(null);
  const onThemeChanged = (event: MessageEvent) => {
    if (
      (event.origin === 'https://dashboard.moonpay.com' ||
        event.origin === 'http://localhost:3000') &&
      event.data &&
      event.data.event === 'themeChanged'
    ) {
      const { themeConfig: newThemeConfig } = event.data;

      setThemeConfiguration({
        themeConfig: { config: newThemeConfig },
        name: '',
        accountIconUrl: null,
      });
    }
  };
  useEffect(() => {
    window.addEventListener('message', onThemeChanged);

    return () => {
      window.removeEventListener('message', onThemeChanged);
    };
  }, []);
  return themeConfiguration;
};
