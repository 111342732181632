// import  first to make sure zone.js is loaded
import {
  clocksNow,
  computeRawError,
  computeStackTrace,
  deepClone,
} from '@datadog/browser-core';
import type { Attributes, Span, Tracer } from '@opentelemetry/api';
import {
  context as otelContext,
  SpanStatusCode,
  trace,
} from '@opentelemetry/api';
import * as logsAPI from '@opentelemetry/api-logs';
import { SeverityNumber } from '@opentelemetry/api-logs';
import '@opentelemetry/context-zone';
import {
  SEMRESATTRS_SERVICE_NAME,
  SEMRESATTRS_SERVICE_VERSION,
} from '@opentelemetry/semantic-conventions';
import { SEMRESATTRS_DEPLOYMENT_ENVIRONMENT } from '@opentelemetry/semantic-conventions/build/src/resource/SemanticResourceAttributes';
import { v4 as uuidv4 } from 'uuid';
import type { ConfigArg } from './config';
import { getConfig } from './config';
import { init as initTracing } from './tracing';
import type { ErrorContext, InitConfig, LoggerPayload, Mode } from './types';
import { Severity } from './types';
import { isPromise } from './utils/isPromise';
import { LogPayloadParser } from './utils/LogPayloadParser';

const sessionIdSymbol = Symbol('session_id');

export class Logger {
  private payloadParser: LogPayloadParser;

  private context: Attributes = {};

  private mode: Mode = 'silent';

  private tracer: Tracer;

  private logger: logsAPI.Logger;

  private initialized = false;

  private SeverityToSeverityNumber: Record<Severity, SeverityNumber> = {
    [Severity.DEBUG]: SeverityNumber.DEBUG,
    [Severity.INFO]: SeverityNumber.INFO,
    [Severity.WARN]: SeverityNumber.WARN,
    [Severity.ERROR]: SeverityNumber.ERROR,
  };

  constructor() {
    this.payloadParser = new LogPayloadParser();
    this.tracer = trace.getTracer('default-tracer');
    this.logger = logsAPI.logs.getLogger('logger');
  }

  public init(config: InitConfig) {
    const sessionId = config.sessionId ?? uuidv4();
    this.context = {
      // snake case on purpose as that's the convention used by DD
      session_id: sessionId,
      commitHash:
        process.env.NEXT_PUBLIC_VERCEL_GIT_COMMIT_SHA ||
        process.env.REACT_APP_VERCEL_GIT_COMMIT_SHA ||
        '',
      [SEMRESATTRS_SERVICE_VERSION]: config.packageVersion ?? '0.0.0',
      [SEMRESATTRS_SERVICE_NAME]: config.service,
      'dd.service': config.service,
      env: config.env,
      [SEMRESATTRS_DEPLOYMENT_ENVIRONMENT]: config.env,
    };
    initTracing({
      env: config.env,
      outputToConsole: config.mode === 'console',
      enableAutoInstrumentation: config.enableAutoInstrumentation,
      context: this.context,
    });
    this.setGlobalContext(config);
    this.payloadParser.addRestrictedKeys(config.restrictedKeys || []);
    this.tracer = trace.getTracer(config.service, config.packageVersion);
    this.mode = config.mode;
    this.logger = logsAPI.logs.getLogger(config.service);
    this.initialized = true;
  }

  public info(message: string, payload?: LoggerPayload, error?: unknown): void {
    this.constructLog(Severity.INFO, message, payload, error);
  }

  public debug(
    message: string,
    payload?: LoggerPayload,
    error?: unknown,
  ): void {
    this.constructLog(Severity.DEBUG, message, payload, error);
  }

  public warn(message: string, payload?: LoggerPayload, error?: unknown): void {
    this.constructLog(Severity.WARN, message, payload, error);
  }

  public error(
    message: string,
    payload?: LoggerPayload,
    error?: unknown,
  ): void {
    this.constructLog(Severity.ERROR, message, payload, error);
  }

  public buildErrorContext(error?: Error): ErrorContext | null {
    if (!error) {
      return null;
    }
    const stackTrace = computeStackTrace(error);
    const rawError = computeRawError({
      stackTrace,
      originalError: error,
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      nonErrorPrefix: 'Provided' as any,
      source: 'logger',
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      handling: 'handled' as any,
      startClocks: clocksNow(),
    });

    return {
      stack: rawError.stack,
      kind: rawError.type,
      message: rawError.message,
    };
  }

  public setGlobalContext(globalContext: {
    session_id?: string;
    apiKey?: string;
  }): void {
    this.context = {
      ...this.context,
      ...globalContext,
    };
    otelContext.active().setValue(sessionIdSymbol, this.context.session_id);
  }

  public withSpan<T>(
    config: ConfigArg,
    callback: (() => T) | ((span: Span) => T),
  ): T {
    const { spanName, options: optionsFromConfig } = getConfig(config);
    const options = optionsFromConfig || {};
    options.attributes = this.payloadParser.objectToOtelNotation({
      ...this.context,
      ...options?.attributes,
    });

    return this.tracer.startActiveSpan(spanName, options, (span) =>
      otelContext.with(
        otelContext.active(),
        (): T => {
          let returnValue: T | null = null;
          try {
            returnValue = callback(span);
            if (isPromise(returnValue)) {
              return (returnValue
                .then((v) => {
                  this.setOk(span);
                  return v;
                })
                .catch((e) => {
                  this.setErr(span, e.message);
                  throw e;
                })
                .finally(() => {
                  span.end();
                }) as unknown) as T;
            }
            this.setOk(span);
            return returnValue;
          } catch (e) {
            this.setErr(span, e instanceof Error ? e.message : `${e}`);
            throw e;
          } finally {
            if (!isPromise(returnValue)) {
              span.end();
            }
          }
        },
      ),
    );
  }

  private constructLog(
    severity: Severity,
    message: string,
    payload?: LoggerPayload,
    errorArg?: unknown,
  ): void {
    try {
      const clonedPayload = deepClone(payload);

      if (this.mode === 'silent') {
        return;
      }
      if (!this.initialized) {
        throw new Error('Logger has to be initialize first!');
      }
      // DD does some smart magic with errors so it's better to pass it like so.
      let error = errorArg;
      if (clonedPayload?.error instanceof Error) {
        if (!error) {
          error = clonedPayload.error;
        }
        delete clonedPayload.error;
      }
      const errorContext =
        error instanceof Error ? this.buildErrorContext(error) : error;

      const sanitisedPayload = this.payloadParser.sanitisePayload({
        level: severity,
        data: clonedPayload,
        origin: window.location.origin,
        ...this.context,
        ...(errorContext ? { error: errorContext } : undefined),
        logMessage: message,
      });

      this.logger.emit({
        body: message,
        attributes: this.payloadParser.objectToOtelNotation({
          ...sanitisedPayload,
          ...this.getActiveDDSpanIdAndTraceId(),
        }),
        severityText: severity,
        severityNumber: this.SeverityToSeverityNumber[severity],
        context: otelContext.active(),
      });
    } catch (error) {
      this.error(
        'Error was thrown whilst constructing a log',
        {
          logMessage: message,
          logPayloadKeys: Object.keys(payload as object),
        },
        error,
      );
    }
  }

  private getActiveDDSpanIdAndTraceId(): {
    'dd.trace_id'?: string;
    'dd.span_id'?: string;
  } {
    const currentSpan = trace.getActiveSpan();
    if (!currentSpan) {
      return {};
    }
    const { spanId, traceId } = currentSpan.spanContext();
    const traceIdEnd = traceId.slice(traceId.length / 2);
    return {
      'dd.trace_id': BigInt(`0x${traceIdEnd}`).toString(),
      'dd.span_id': BigInt(`0x${spanId}`).toString(),
    };
  }

  private setOk(span: Span): void {
    span.setStatus({ code: SpanStatusCode.OK });
  }

  private setErr(span: Span, errorMsg: string): void {
    span.setStatus({ code: SpanStatusCode.ERROR, message: errorMsg });
  }
}
