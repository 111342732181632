import { FunctionComponent } from 'react';

const EthereumIcon: FunctionComponent = () => {
  return (
    <svg
      width="64"
      height="64"
      viewBox="0 0 48 48"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <circle cx="24" cy="24" r="24" fill="#565C8D" />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M14 23.5875L23.997 7L33.9941 23.5875L23.997 29.4967L23.997 29.4968L23.9969 29.4967L14 23.5875ZM23.9969 40.9305L14 26.8431L23.997 32.7494L34 26.8431L23.997 40.9304V40.9307L23.9969 40.9306L23.9969 40.9305Z"
        fill="#E9EBF0"
      />
    </svg>
  );
};

export default EthereumIcon;
