import { FunctionComponent, ReactElement } from 'react';

const ChangeNetworkRequestIcon: FunctionComponent = (): ReactElement => (
  <svg
    width="72"
    height="72"
    viewBox="0 0 72 72"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <rect width="72" height="72" rx="22.5" fill="#7D00FF" fillOpacity="0.08" />
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M42.6161 20.1161C43.1043 19.628 43.8957 19.628 44.3839 20.1161L51.8839 27.6161C52.1183 27.8505 52.25 28.1685 52.25 28.5C52.25 28.8315 52.1183 29.1495 51.8839 29.3839L44.3839 36.8839C43.8957 37.372 43.1043 37.372 42.6161 36.8839C42.128 36.3957 42.128 35.6043 42.6161 35.1161L47.9822 29.75L28.5 29.75C27.8096 29.75 27.25 29.1904 27.25 28.5C27.25 27.8096 27.8096 27.25 28.5 27.25L47.9822 27.25L42.6161 21.8839C42.128 21.3957 42.128 20.6043 42.6161 20.1161ZM29.3839 35.1161C29.872 35.6043 29.872 36.3957 29.3839 36.8839L24.0178 42.25H43.5C44.1904 42.25 44.75 42.8096 44.75 43.5C44.75 44.1904 44.1904 44.75 43.5 44.75H24.0178L29.3839 50.1161C29.872 50.6043 29.872 51.3957 29.3839 51.8839C28.8957 52.372 28.1043 52.372 27.6161 51.8839L20.1161 44.3839C19.628 43.8957 19.628 43.1043 20.1161 42.6161L27.6161 35.1161C28.1043 34.628 28.8957 34.628 29.3839 35.1161Z"
      fill="#7D00FF"
    />
  </svg>
);

export default ChangeNetworkRequestIcon;
