const isValidColorHex = (hex: string) =>
  /(^#[0-9A-F]{6}$)|(^#[0-9A-F]{3}$)/i.test(hex);

const getColorCode = (
  defaultColorCode?: string,
): {
  lightThemePrimary: string;
  darkThemePrimary: string;
} => {
  try {
    const url: URL = new URL(window.location.href);

    if (url.pathname.includes('abra')) {
      return {
        lightThemePrimary: '#3D2D86',
        darkThemePrimary: '#3D2D86',
      };
    }

    if (
      url.hostname.includes('changelly') ||
      url.pathname.includes('changelly')
    ) {
      return {
        lightThemePrimary: '#10D078',
        darkThemePrimary: '#10D078',
      };
    }

    if (url.pathname.includes('cryptofish')) {
      return {
        lightThemePrimary: '#ff80bf',
        darkThemePrimary: '#ff80bf',
      };
    }

    if (url.pathname.includes('litecoin')) {
      return {
        lightThemePrimary: '#3583f6',
        darkThemePrimary: '#3583f6',
      };
    }

    const colorCodeParam = url.searchParams.get('colorCode') ?? '';

    let [lightThemePrimary, darkThemePrimary] = colorCodeParam.split(',');

    if (!lightThemePrimary || !isValidColorHex(lightThemePrimary)) {
      lightThemePrimary = defaultColorCode || '#7D00FF';
    }

    if (!darkThemePrimary || !isValidColorHex(darkThemePrimary)) {
      darkThemePrimary = lightThemePrimary;
    }

    return {
      lightThemePrimary,
      darkThemePrimary,
    };
  } catch {
    return {
      lightThemePrimary: '#7D00FF',
      darkThemePrimary: '#7D00FF',
    };
  }
};

export default getColorCode;
