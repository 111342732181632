import { FunctionComponent } from 'react';
import { IconProps } from './types';

const Icon: FunctionComponent<IconProps> = ({ icon, iconAlt, className }) => {
  const LinkIcon = icon;

  switch (typeof icon) {
    case 'function':
      return <LinkIcon className={className} />;
    case 'string':
    default:
      return <img className={className} src={icon as string} alt={iconAlt} />;
  }
};

export default Icon;
