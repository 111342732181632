// has to be the first import
import { ThemeProvider } from '@moonpay-widget/ui-kit';
import { createRef, RefObject, Suspense } from 'react';
import ReactDOM from 'react-dom';
import { Route, BrowserRouter as Router, Routes } from 'react-router-dom';
import SecureWallet from 'src/components/SecureWallet';
import lazyWithRetry from 'src/utils/lazyWithRetry';
import { WalletService } from 'src/wallet/services/walletService';
import logger from './utils/logger';

import './i18n';
import './index.css';
import './v2/index';

try {
  const promptRef: RefObject<any> = createRef();

  const Web3LoginImported = lazyWithRetry(async () => {
    const { Web3Login } = await import('src/modules/Web3');
    return { default: Web3Login };
  });

  WalletService.bootstrapSecureWallet(promptRef).then(({ uxMode, theme }) => {
    ReactDOM.render(
      <Suspense fallback={<></>}>
        <Router>
          <ThemeProvider themeConfig={theme}>
            <Routes>
              {/* Originally from moonpay-buy apps/widget/src/Router.tsx:180 */}
              <Route path="/web3-login" element={<Web3LoginImported />} />
              {/* Default functionality */}
              <Route
                index
                element={<SecureWallet uxMode={uxMode} promptRef={promptRef} />}
              />
            </Routes>
          </ThemeProvider>
        </Router>
      </Suspense>,
      document.getElementById('root'),
    );
  });
} catch (error) {
  logger.error('Secure wallet initialization failed', { error });
  throw error;
}
