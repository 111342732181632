export interface LoggerPayload {
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any;
}

export type Mode = 'standard' | 'console' | 'silent';
export type Env = 'production' | 'staging' | 'dev';

export type InitConfig = {
  packageVersion?: string;
  service: string;
  env: Env;
  sessionSampleRate?: number;
  mode: Mode;
  sessionId?: string;
  apiKey?: string;
  restrictedKeys?: string[];
  enableAutoInstrumentation?: boolean;
};

export enum Severity {
  DEBUG = 'debug',
  INFO = 'info',
  WARN = 'warn',
  ERROR = 'error',
}

export interface ErrorContext {
  kind?: string;
  stack?: string;
  message: string;
}
