import clsx from 'clsx';
import './Loader.css';
import { LoaderProps } from './types';

const Loader = ({
  className,
  time,
  borderWidth,
  color = 'initial',
  ...props
}: LoaderProps) => (
  <div
    {...props}
    className={clsx('bg-transparent rounded-full m-auto', className)}
    style={{
      animation: `${time || 1}s loaderSpin linear infinite`,
      borderWidth: borderWidth || 2,
      borderColor: color,
      borderBottomColor: 'transparent',
      borderStyle: 'solid',
      height: '1.1875rem',
      width: '1.1875rem',
    }}
  />
);

export default Loader;
