import clsx from 'clsx';
import React, { ReactElement } from 'react';
import Text from '../Text';
import { LabelProps } from './types';

const Label: React.FunctionComponent<LabelProps> = ({
  as = 'label',
  children,
  className,
  flex = false,
  margin = 'mb-2',
  textColor = 'text-label-secondary',
  ...props
}): ReactElement => {
  return (
    <Text
      variant="footnote"
      as={as}
      className={clsx(
        flex ? 'inline-flex' : 'inline-block',
        margin !== false && margin,
        `rounded-foreground ${textColor}`,
        className,
      )}
      {...props}
    >
      {children}
    </Text>
  );
};

export default Label;
