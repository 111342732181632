import { FunctionComponent, ReactElement } from 'react';

const PlusSmIcon: FunctionComponent<{ className?: string }> = ({
  className,
}): ReactElement => (
  <svg
    className={className}
    fill="none"
    height="20"
    viewBox="0 0 21 20"
    width="21"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      fillRule="evenodd"
      clipRule="evenodd"
      d="M10.6667 5C11.219 5 11.6667 5.44772 11.6667 6V9L14.6667 9C15.219 9 15.6667 9.44772 15.6667 10C15.6667 10.5523 15.219 11 14.6667 11H11.6667V14C11.6667 14.5523 11.219 15 10.6667 15C10.1145 15 9.66675 14.5523 9.66675 14V11H6.66675C6.11446 11 5.66675 10.5523 5.66675 10C5.66675 9.44771 6.11446 9 6.66675 9L9.66675 9V6C9.66675 5.44772 10.1145 5 10.6667 5Z"
      fill="white"
    />
  </svg>
);

export default PlusSmIcon;
