import { FunctionComponent } from 'react';
import Text from '../Text';
import MoonPayIcon from './Logo';

export type FooterVariant = 'primary' | 'blackAndWhite';

export interface FooterProps {
  variant?: FooterVariant;
}
const Footer: FunctionComponent<FooterProps> = ({ variant = 'primary' }) => {
  return (
    <footer className="bg-system-background-primary absolute bottom-0 h-10 w-full flex items-center justify-center self-center">
      <Text variant="footnote" className="mr-1">
        Powered by
      </Text>
      <div className="flex items-center">
        <MoonPayIcon
          style={{
            color: `${
              variant === 'blackAndWhite' ? 'black' : 'var(--moonpay-brand)'
            }`,
          }}
        />
        <Text bold variant="subhead" className="mx-1">
          MoonPay
        </Text>
      </div>
    </footer>
  );
};

export default Footer;
