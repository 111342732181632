import { useContext } from 'react';
import ColorSchemeContext from 'src/contexts/ColorScheme/ColorSchemeContext';
import { UseColorSchemeResult } from 'src/contexts/ColorScheme/types';
import { ErrorManager } from 'src/utils/errorManager';

const errorManager = new ErrorManager(__filename);

const useColorScheme = (): UseColorSchemeResult => {
  const context = useContext(ColorSchemeContext);

  if (context === undefined) {
    throw errorManager.getServerError(
      'useColorScheme',
      `useColorScheme must be used within a ColorSchemeProvider`,
    );
  }

  return context;
};

export default useColorScheme;
