import getColorCode from './getColorCode';

// This is to ensure backwards compatibility with the old colorCode,
// pathname and hostname dependent colors.
const { lightThemePrimary, darkThemePrimary } = getColorCode();

const moonPayDefaultTheme = {
  name: 'MoonPay Default',
  accountIconUrl: null,
  themeConfig: {
    config: {
      fontUrl: null,
      fontFamily: null,
      light: {
        accentPrimary: lightThemePrimary,
        accentSecondary: '#37a970',
        accentTertiary: '#dab451',
        accentQuaternary: '#D52A3E',
        accentLabelPrimary: lightThemePrimary,
        accentLabelSecondary: '#2c8759',
        accentLabelTertiary: '#be8f19',
        accentLabelQuaternary: '#d52a3e',
        grey1: '#898395',
        grey2: '#b0acb9',
        grey3: '#bcb8c2',
        grey4: '#c8c6ce',
        grey5: '#e2e1e5',
        grey6: '#fafafb',
        sysBackgroundPrimary: '#ffffff',
        sysBackgroundSecondary: '#f7f7f8',
        groupBackgroundPrimary: '#f7f7f8',
        groupBackgroundSecondary: '#ffffff',
        labelPrimary: '#221a39',
        labelSecondary: 'rgba(34, 26, 57, 0.6)',
        labelTertiary: 'rgba(34, 26, 57, 0.3)',
        labelQuaternary: 'rgba(34, 26, 57, 0.18)',
        buttonPrimary: lightThemePrimary,
        buttonLabel: '#ffffff',
        buttonRadius: '624.9375rem', // full - 9999px
        widgetRadius: '2.5rem', // 40px
        widgetElevation: '0px 4px 100px rgba(0, 0, 0, 0.2)',
        foregroundRadius: '0.75rem', // 12px
        dividerNonOpaque: 'rgba(121, 120, 128, 0.2)',
        dividerOpaque: '#c6c6c8',
        fillPrimary: 'rgba(121, 120, 128, 0.2)',
        fillSecondary: 'rgba(121, 120, 128, 0.16)',
        fillTertiary: 'rgba(120, 118, 128, 0.12)',
        fillQuaternary: 'rgba(118, 116, 128, 0.08)',
        accentFillPrimary: 'rgba(125, 0, 255, 0.08)',
        accentFillSecondary: 'rgba(55, 169, 112, 0.12)',
        accentFillTertiary: 'rgba(218, 180, 81, 0.12)',
        accentFillQuaternary: 'rgba(213, 42, 62, 0.08)',
        iconUrl: null,
        borderColor: '#f7f7f8',
      },
      dark: {
        accentPrimary: '#ffffff',
        accentSecondary: '#37a970',
        accentTertiary: '#dab451',
        accentQuaternary: '#DD5565',
        accentLabelPrimary: '#c38aff',
        accentLabelSecondary: '#36a56d',
        accentLabelTertiary: '#be8f19',
        accentLabelQuaternary: '#f56172',
        grey1: '#898395',
        grey2: '#62606a',
        grey3: '#48454d',
        grey4: '#3a3a3c',
        grey5: '#2d2c2e',
        grey6: '#1e1c1e',
        sysBackgroundPrimary: '#1c1c1e',
        sysBackgroundSecondary: '#2c2c2e',
        groupBackgroundPrimary: '#1c1c1e',
        groupBackgroundSecondary: '#2c2c2e',
        labelPrimary: '#ffffff',
        labelSecondary: 'rgba(235, 235, 245, 0.6)',
        labelTertiary: 'rgba(235, 235, 245, 0.3)',
        labelQuaternary: 'rgba(235, 235, 245, 0.18)',
        buttonPrimary: darkThemePrimary,
        buttonLabel: '#ffffff',
        buttonRadius: '624.9375rem', // full - 9999px
        widgetRadius: '2.5rem', // 40px
        widgetElevation: '0px 4px 100px rgba(0, 0, 0, 0.2)',
        foregroundRadius: '0.75rem', // 12px
        dividerNonOpaque: 'rgba(84, 84, 88, 0.6)',
        dividerOpaque: '#38383a',
        fillPrimary: 'rgba(120, 120, 128, 0.36)',
        fillSecondary: ' rgba(120, 120, 128, 0.32)',
        fillTertiary: 'rgba(118, 118, 128, 0.24)',
        fillQuaternary: 'rgba(118, 118, 128, 0.18)',
        accentFillPrimary: 'rgba(118, 118, 128, 0.18)',
        accentFillSecondary: 'rgba(55, 169, 112, 0.14)',
        accentFillTertiary: 'rgba(218, 180, 81, 0.08)',
        accentFillQuaternary: 'rgba(221, 86, 101, 0.12)',
        iconUrl: null,
        borderColor: '#2c2c2e',
      },
      version: 1,
    },
  },
};

export default moonPayDefaultTheme;
